import { useQuery } from "@tanstack/react-query";
import { noop } from "lodash";
import { apiFetch } from "../../utils/apiFetch";
import { Application } from "../../utils/constants";

export function useApiQuery<T>({
  key,
  mock,
  path,
  validate = noop,
}: {
  key?: string;
  mock: T;
  path: (typeof Application.Api)[keyof typeof Application.Api];
  validate?: (
    params: Readonly<{ key?: string; mock: T; path?: string }>
  ) => void;
}) {
  const result = useQuery<T>({
    queryKey: [path, key],
    queryFn: () => {
      const params = {
        mock,
        token: key,
        url: path,
      } as const;
      validate(params);
      return apiFetch(params);
    },
  });
  return result;
}
