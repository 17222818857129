import { Color, ColorCSS, DimensionPx, FontSizeEm } from "../utils/constants";
import { mergeStyles } from "../utils/react";
import { LifeCycleTransition } from "./LifeCycleTransition";
import { useAppState } from "./hooks/useAppDuck";

interface AppErrorToastProps extends React.HTMLProps<HTMLDivElement> {
  isVisible?: boolean;
}

export function AppErrorToast({
  isVisible,
  style,
  ...wrapperProps
}: AppErrorToastProps) {
  const { error } = useAppState();

  const wrapperStyle = mergeStyles(AppErrorToast.wrapperStyle, style);

  return (
    <LifeCycleTransition
      dismount={!isVisible}
      style={wrapperStyle}
      {...wrapperProps}
    >
      {error}
    </LifeCycleTransition>
  );
}

AppErrorToast.wrapperStyle = {
  backgroundColor: ColorCSS[Color.FOREGROUND],
  borderRadius: DimensionPx.Layout.SPACING_2x,
  color: ColorCSS[Color.ALERT],
  fontWeight: "bold",
  fontSize: FontSizeEm.LARGE,
  padding: DimensionPx.Layout.SPACING_3x,
  marginBottom:
    DimensionPx.Button.HEIGHT +
    (DimensionPx.Button.PADDING + DimensionPx.Layout.SPACING_1x) * 2,
  textAlign: "center",
} as React.CSSProperties;
