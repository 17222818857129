import { merge } from "lodash";
import { Color, ColorCSS, Timing } from "./constants";

export function mergeStyles(
  ...styleProps: (React.CSSProperties | undefined)[]
): React.CSSProperties {
  const [style, ...rest] = styleProps;
  return merge({}, style, ...rest);
}

export function asCssTransition(prop: string | TemplateStringsArray) {
  return `${prop} ${Timing.Secs.Transition.DURATION}s ease-in-out`;
}

export function asCssTransitions(...props: string[]) {
  return props.map((p) => asCssTransition(p)).join(",");
}

/**
 * Return intermediate color mix css property
 * @param colorA css color constant name
 * @param colorB css color constant name
 * @param stopA ratio 0..1
 * @param stopB ratio 0..1
 * @returns
 */
export function cssColorMix(
  colorA: Color,
  colorB: Color,
  stopA: number,
  stopB: number
) {
  const colorMix = (
    [
      [colorA, stopA],
      [colorB, stopB],
    ] as const
  )
    .map(
      ([colorName, stopRatio]) => `${ColorCSS[colorName]} ${stopRatio * 100}%`
    )
    .join(",");
  return `color-mix(in srgb, ${colorMix})`;
}

export function componentKey(
  Component: React.ComponentType<any>,
  idx: unknown
) {
  return `${Component.name}-${idx}`;
}

export function setConditionalTimeout(
  /** When true the callback is called and timeout is terminated */
  predicate: () => boolean,
  /** Call function immediately before using timeout delay */
  immediate: boolean,
  ...[callback, ...args]: Parameters<typeof window.setTimeout>
) {
  const schedule = () => window.setTimeout(conditionalCallback, ...args);
  const conditionalCallback = () => (predicate() ? callback() : schedule());
  return immediate ? conditionalCallback() : schedule();
}
