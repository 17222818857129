import React from "react";

export function useInterval(
  ...[callback, ...params]: Parameters<typeof window.setTimeout>
) {
  const timeoutRef = React.useRef<NodeJS.Timeout>();
  const [, setCycles] = React.useState(0);

  if (!timeoutRef.current) {
    timeoutRef.current = setTimeout(() => {
      try {
        callback();
      } finally {
        timeoutRef.current = undefined;
        setCycles((prev) => prev + 1);
      }
    }, ...params);
  }

  return timeoutRef.current;
}
