import React from "react";
import { ProductDetails } from "../../utils/constants";
import { useSetOnceOnly } from "./useValues";
import _ from "lodash";

export enum StorageKey {
  VIEWED_PRODUCTS = "viewed-products",
}

interface OfflineStorage {
  [StorageKey.VIEWED_PRODUCTS]: ReadonlyArray<
    ProductDetails & { viewed: Date }
  >;
}

const OfflineStorageDefaults: OfflineStorage = {
  [StorageKey.VIEWED_PRODUCTS]: [],
} as const;

export const OfflineStorageContext = React.createContext<
  ReturnType<typeof useOfflineStorageContextValue>
>([OfflineStorageDefaults, () => undefined]);

export function useOfflineStorage(
  key: StorageKey
): readonly [
  OfflineStorage[typeof key],
  React.Dispatch<React.SetStateAction<OfflineStorage[typeof key]>>
] {
  useSetOnceOnly(key);

  const [contextValue, setKeyedValue] = React.useContext(OfflineStorageContext);
  const value = contextValue[key];

  const setValue = React.useCallback<
    React.Dispatch<React.SetStateAction<OfflineStorage[typeof key]>>
  >(
    (setter) => {
      const newValue = _.isFunction(setter) ? setter(value) : setter;
      setKeyedValue(key, newValue);
    },
    [key, value, setKeyedValue]
  );

  return [value, setValue];
}

const getOfflineStorageItem = (key: StorageKey) => {
  const storedValue = window.localStorage.getItem(key);
  return storedValue ? JSON.parse(storedValue) : OfflineStorageDefaults[key];
};

const setOfflineStorageItem = (
  key: StorageKey,
  value: OfflineStorage[typeof key]
) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

export function useOfflineStorageContextValue() {
  const initialContextValue = React.useMemo(() => {
    return Object.values(StorageKey).reduce(
      (offlineStorageValue, key) => ({
        ...offlineStorageValue,
        [key]: getOfflineStorageItem(key),
      }),
      {} as OfflineStorage
    );
  }, []);
  const [value, setValue] = React.useState(initialContextValue);

  const setKeyedValue = React.useCallback(
    (key: StorageKey, value: OfflineStorage[typeof key]) => {
      setValue((currentValue) => {
        setOfflineStorageItem(key, value);
        return { ...currentValue, [key]: value };
      });
    },
    []
  );

  return [value, setKeyedValue] as const;
}
