import React from "react";
import { DimensionPx } from "../utils/constants";
import { componentKey, mergeStyles } from "../utils/react";
import { AccountProfile } from "./AccountProfile";
import { AppControls } from "./AppControls";
import { AppTitle } from "./AppTitle";
import { AuthForm } from "./AuthForm";
import ErrorResetBoundary from "./ErrorResetBoundary";
import { ManageProduct } from "./ManageProduct";
import { AuthCode } from "./AuthCode";
import { PageFlowSection } from "./PageFlowSection";
import { ProductInformation } from "./ProductInformation";
import { ScanProduct } from "./ScanProduct";
import { SearchProduct } from "./SearchProduct";
import { UnAuthOptions } from "./UnAuthOptions";

interface PageFlowProps extends React.HTMLProps<HTMLDivElement> {}

export function PageFlow({ style, ...divProps }: PageFlowProps) {
  const pageSections = [
    UnAuthOptions,
    AuthForm,
    AuthCode,
    ScanProduct,
    SearchProduct,
    ManageProduct,
    ProductInformation,
    AccountProfile,
  ] as const;
  return (
    <>
      <AppTitle />
      <ErrorResetBoundary>
        {pageSections.map((Component, i) => (
          <PageFlowSection
            {...divProps}
            key={componentKey(Component, i)}
            name={Component.name}
            shouldRenderPage={
              Component.shouldRenderPage ??
              (({ page }) => !!page && Component.pages.has(page))
            }
            style={mergeStyles(PageSectionStyles.SHARED, style, {
              flexDirection: "column",
              height: `calc(100vh - ${DimensionPx.Title.HEIGHT / 2})`,
              justifyContent: "end",
            })}
          >
            <Component style={PageSectionStyles.MAIN} />
          </PageFlowSection>
        ))}
      </ErrorResetBoundary>
      <AppControls style={PageSectionStyles.CONTROLS} />
    </>
  );
}

const PageSectionStyles = {
  SHARED: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    position: "absolute",
    width: "100vw",
  },
  get MAIN() {
    return {
      ...this.SHARED,
      flexDirection: "column",
      gap: DimensionPx.Layout.SPACING_3x,
      height: `calc(100vh - ${AppControls.bottomOffsetPx}px)`,
      justifyContent: "end",
      top: 0,
    };
  },
  get CONTROLS() {
    return {
      ...this.SHARED,
      alignItems: "start",
      flexDirection: "row",
      gap: DimensionPx.Layout.SPACING_1x,
      height: DimensionPx.Button.HEIGHT + DimensionPx.Layout.SPACING_3x,
      justifyContent: "space-evenly",
    };
  },
} as Readonly<Record<string, Partial<React.CSSProperties>>>;
