import React from "react";
import {
  AppError,
  Color,
  ColorCSS,
  DimensionPx,
  Timing,
} from "../utils/constants";
import { mergeStyles, setConditionalTimeout } from "../utils/react";
import { LifeCycleTransition } from "./LifeCycleTransition";

interface PolicyDocProps extends React.HTMLProps<HTMLDivElement> {
  /** Document name */
  doc: string;
  isVisible?: boolean;
}

export function PolicyDoc({
  doc,
  isVisible = false,
  style,
  ...wrapperProps
}: PolicyDocProps) {
  const wrapperStyle = mergeStyles(PolicyDoc.wrapperStyle, style);

  return (
    <LifeCycleTransition
      dismount={!isVisible}
      style={wrapperStyle}
      {...wrapperProps}
    >
      <DocContent doc={doc} />
    </LifeCycleTransition>
  );
}

PolicyDoc.wrapperStyle = {
  backgroundColor: ColorCSS[Color.FOREGROUND],
  display: "flex",
  flexDirection: "column",
  left: 0,
  maxHeight: "100vh",
  maxWidth: "100vw",
  overflowY: "scroll",
  padding: DimensionPx.Layout.SPACING_3x,
  position: "absolute",
  top: 0,
} as Partial<React.CSSProperties>;

function DocContent(props: Pick<PolicyDocProps, "doc">) {
  const mdViewRef = React.createRef<HTMLElement>();

  React.useEffect(() => {
    setConditionalTimeout(
      () => Boolean(mdViewRef.current && mdViewRef.current.shadowRoot),
      false,
      () => {
        const parentElement = mdViewRef.current?.shadowRoot;
        if (!parentElement) {
          throw new Error(AppError.POLICY_DOC_UNCONNECTED_ELEMENT);
        }
        const styleElementId = "custom-markdown-style";
        const styleElement =
          parentElement.getElementById(styleElementId) ??
          document.createElement("style");
        styleElement.id = styleElementId;
        styleElement.innerHTML = `
.markdown-body {
  background-color: ${ColorCSS[Color.FOREGROUND]};
}
`;
        if (!styleElement.isConnected)
          mdViewRef.current.shadowRoot.append(styleElement);
      },
      Timing.Millis.ONE_SEC
    );
  }, [mdViewRef]);

  return (
    <github-md-view
      url={`./docs/${props.doc}.md`}
      ref={mdViewRef}
    ></github-md-view>
  );
}
