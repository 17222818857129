import React from "react";
import { Page } from "../utils/constants";
import { mergeStyles } from "../utils/react";
import { ProductList } from "./ProductList";
import { useOwnedProducts } from "./hooks/useOwnedProducts";

interface ManageProductProps extends React.HTMLProps<HTMLDivElement> {}

/**
 * List of products that the user owns
 */
export function ManageProduct({ style, ...divProps }: ManageProductProps) {
  const wrapperStyle = mergeStyles(ManageProduct.wrapperStyle, style);
  const [ownedProducts, removeProduct] = useOwnedProducts();

  return (
    <div style={wrapperStyle} {...divProps}>
      <ProductList
        items={ownedProducts}
        removeItem={removeProduct}
        style={{ paddingTop: "50vh" }}
      />
    </div>
  );
}

ManageProduct.pages = Object.freeze(new Set([Page.PRODUCT_PACKAGES]));
ManageProduct.shouldRenderPage = undefined;
ManageProduct.wrapperStyle = {} as React.CSSProperties;
