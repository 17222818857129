import React from "react";
import { useDispatch, useSelector } from "react-ducks";
import { AppState } from "../../utils/constants";
import * as appDuck from "../ducks/app";

/** Wrapper around the use app duck default selector */
export function useAppState(): AppState {
  const appState = useSelector(appDuck.default.selectors.$) ?? {};
  return appState;
}

function useAppStateSetter<T extends appDuck.ActionType>(action: T) {
  const actionDispatcher = useDispatch(appDuck.default.actions[action]);
  return React.useCallback(
    (payload: appDuck.Payload<T>) => {
      actionDispatcher(payload);
    },
    [actionDispatcher]
  );
}

export function useAccountSetter() {
  return useAppStateSetter(appDuck.ActionType.SET_ACCOUNT);
}

export function useApiKeySetter() {
  const setAccount = useAccountSetter();

  return React.useCallback(
    (
      apiKey: NonNullable<
        NonNullable<Parameters<typeof setAccount>[0]>["api"]
      >["key"]
    ) => {
      setAccount({ api: { key: apiKey } });
    },
    [setAccount]
  );
}

export function useProductIdSetter() {
  return useAppStateSetter(appDuck.ActionType.SET_PRODUCT_ID);
}

/** Wrapper around the app duck set page action */
export function useAppPageSetter() {
  return useAppStateSetter(appDuck.ActionType.SET_PAGE);
}

/** Wrapper around the app duck set modal action */
export function useAppModalSetter() {
  return useAppStateSetter(appDuck.ActionType.SET_MODAL);
}

export function useAppErrorSetter() {
  const setAppError = useAppStateSetter(appDuck.ActionType.SET_ERROR);
  return (...args: Parameters<typeof setAppError>) => {
    // TODO: reportWebVitals
    console.error(...args);
    setAppError(...args);
  };
}
