import React from "react";

export function useLifeCycle({
  dismount,
  onMount,
  onDismount,
}: {
  dismount: boolean;
  onMount: React.TransitionFunction;
  onDismount: React.TransitionFunction;
}) {
  const [, startTransition] = React.useTransition();

  React.useLayoutEffect(() => {
    startTransition(dismount ? onDismount : onMount);
    // return () => startTransition(onDismount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dismount]);
}
