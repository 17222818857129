import _ from "lodash";
import React from "react";
import { FiPackage } from "react-icons/fi";
import { MdAccountCircle, MdQrCodeScanner } from "react-icons/md";
import { TbListSearch } from "react-icons/tb";
import {
  Color,
  ColorCSS,
  DimensionPx,
  Page,
  PageLayer,
} from "../utils/constants";
import { asCssTransition, componentKey, mergeStyles } from "../utils/react";
import { ControlButton } from "./ControlButton";
import { LifeCycleTransition } from "./LifeCycleTransition";
import { useAppState } from "./hooks/useAppDuck";
import { usePageSetter } from "./hooks/usePageSetter";

export function AppControls({
  style,
  ...divProps
}: React.HTMLProps<HTMLDivElement>) {
  const { page } = useAppState();
  const dataTestId = AppControls.name;
  const renderPage = page && AppControls.pages.has(page);
  const divStyle = mergeStyles(style, {
    borderTop: `rgba(0,0,0,0.2) solid ${DimensionPx.Layout.SPACING_0x}px`,
  });

  const setPage = usePageSetter();

  return (
    <LifeCycleTransition
      data-testid={dataTestId}
      transition={AppControls.transitionProp}
      style={divStyle}
      {...divProps}
      dismount={!renderPage}
    >
      {_.map(
        ControlButtons,
        (
          { IconComponent }: { IconComponent: React.ComponentType },
          buttonPage: Page
        ) => (
          <ControlButton
            IconComponent={IconComponent}
            isActive={page === buttonPage}
            key={componentKey(ControlButton, buttonPage)}
            onClick={() => setPage({ page: buttonPage })}
          />
        )
      )}
    </LifeCycleTransition>
  );
}

const ControlButtons = {
  [Page.PRODUCT_SCAN]: {
    IconComponent: MdQrCodeScanner,
  },
  [Page.PRODUCT_SEARCH]: {
    IconComponent: TbListSearch,
  },
  [Page.PRODUCT_PACKAGES]: {
    IconComponent: FiPackage,
  },
  [Page.ACCOUNT_PROFILE]: {
    IconComponent: MdAccountCircle,
  },
} as const;

AppControls.bottomOffsetPx =
  DimensionPx.Button.HEIGHT + DimensionPx.Layout.SPACING_3x;

AppControls.transitionProp = LifeCycleTransition.getTransitionProp({
  css: {
    default: {
      backgroundColor: ColorCSS[Color.FOREGROUND],
      pointerEvents: "none",
      transition: asCssTransition`background-color`,
      zIndex: PageLayer.ONE,
    },
    mount: {
      pointerEvents: "auto",
      transform: `translate(0, calc(100vh - ${AppControls.bottomOffsetPx}px))`,
    },
  },
});

/** Pages that this component is displayed for */
const hideAppControlsOn = [
  Page.AUTH_CODE,
  Page.AUTH_FORM,
  Page.UN_AUTH,
];
AppControls.pages = Object.freeze(
  new Set(
    Object.values(Page).filter((page) => !hideAppControlsOn.includes(page))
  )
);
