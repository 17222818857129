import React from "react";
import {
  Color,
  ColorCSS,
  DimensionPx,
  FontSizeEm,
  PageLayer,
  PageTitle,
} from "../utils/constants";
import { mergeStyles } from "../utils/react";
import { LifeCycleTransition } from "./LifeCycleTransition";
import { useAppState } from "./hooks/useAppDuck";
import { Title } from "./Title";

interface AppTitleProps extends React.HTMLProps<HTMLDivElement> {}

export function AppTitle({ children, style, ...divProps }: AppTitleProps) {
  const elementProps = {
    style: mergeStyles(AppTitle.wrapperStyle, style),
    ...divProps,
  };

  const { page } = useAppState();
  const dataTestId = AppTitle.name;

  const shouldShowTitle = page && AppTitle.pages.has(page);

  return (
    <LifeCycleTransition
      data-testid={dataTestId}
      dismount={!shouldShowTitle}
      transition={wrapperTransitionProp}
      {...elementProps}
    >
      <Title style={{ fontSize: FontSizeEm.LARGE }}>
        {PageTitle[page as keyof typeof PageTitle]}
      </Title>
      {children}
    </LifeCycleTransition>
  );
}

AppTitle.wrapperStyle = {
  alignItems: "center",
  background: ColorCSS[Color.FOREGROUND],
  display: "flex",
  height: DimensionPx.Title.HEIGHT,
  justifyContent: "center",
  width: "100vw",
  zIndex: PageLayer.ONE,
} as React.CSSProperties;

/** Pages that this component is displayed for */
AppTitle.pages = Object.freeze(new Set(Object.keys(PageTitle)));

const wrapperTransitionProp = LifeCycleTransition.getTransitionProp({
  css: {
    default: {
      transform: "translate(0, -50vh)",
    },
    mount: {
      transform: "translate(0, 0vh)",
    },
    get unmount() {
      return this.default ?? {};
    },
  },
});
