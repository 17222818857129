import React from "react";
import { ProductDetails } from "../../utils/constants";
import { SearchControlParams } from "../SearchControls";

export function useSearchProducts() {
  const [searchResults, setSearchResults] = React.useState<ProductDetails[]>(
    []
  );

  const handleSearchProducts = React.useCallback(function searchProduct(
    params: SearchControlParams
  ) {
    // TODO: properly handle searching
    setSearchResults([]);
  },
  []);

  return [searchResults, handleSearchProducts] as const;
}
