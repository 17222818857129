import React from "react";
import { AppText, DimensionPx, Modal } from "../utils/constants";
import { mergeStyles } from "../utils/react";
import { Link } from "./Link";
import { useAppModalSetter } from "./hooks/useAppDuck";

interface AcceptPolicyProps extends React.HTMLProps<HTMLSpanElement> {}

export function AcceptPolicy(props: AcceptPolicyProps) {
  const { style, ...spanProps } = props;

  const setAppModal = useAppModalSetter();

  return (
    <Span style={mergeStyles(AcceptPolicy.wrapperStyle, style)} {...spanProps}>
      <Span style={spanWrapperStyle}>{AppText.ACCEPT_ACCOUNT}</Span>
      <Span style={spanWrapperStyle}>
        <Link onClick={() => setAppModal(Modal.TERMS_CONDITIONS)}>
          {AppText.TERMS_CONDITIONS}
        </Link>
        {AppText.AND}
        <Link onClick={() => setAppModal(Modal.PRIVACY_POLICY)}>
          {AppText.PRIVACY_POLICY}
        </Link>
      </Span>
    </Span>
  );
}

AcceptPolicy.wrapperStyle = {
  flexDirection: "column",
  paddingBottom: DimensionPx.Layout.SPACING_3x,
} as Partial<React.CSSProperties>;

function Span(props: React.HTMLProps<HTMLSpanElement>) {
  const { style, ...spanProps } = props;
  return <span style={mergeStyles(spanWrapperStyle, style)} {...spanProps} />;
}

const spanWrapperStyle: React.CSSProperties = {
  alignItems: "center",
  display: "flex",
  gap: DimensionPx.Layout.SPACING_1x,
  justifyContent: "center",
  textAlign: "center",
  verticalAlign: "middle",
};
