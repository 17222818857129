import React from "react";
import { Account, Application } from "../../utils/constants";
import * as mocks from "../../utils/mocks";
import { useApiQuery } from "./useApiQuery";
import { useAccountSetter, useAppState } from "./useAppDuck";

export function useAccount() {
  const { api, account } = useAppState();
  const setAccount = useAccountSetter();

  // TODO: remove reliance on mocks in production
  // TODO: handle refresh api token usage
  const result = useApiQuery({
    mock: api?.key && mocks.User,
    path: Application.Api.PROFILE,
    key: api?.key ?? undefined,
  });

  const updateAccount = React.useCallback(
    (accountUpdate: Partial<Account>) => {
      setAccount({
        account: accountUpdate,
      });
    },
    [setAccount]
  );

  const clearAccount = React.useCallback(
    () => void setAccount({ api: { key: null } }),
    [setAccount]
  );

  React.useEffect(() => {
    if (!result.data) {
      updateAccount({ isAuthenticated: false });
    } else {
      updateAccount({
        ...result.data,
        isAuthenticated: !!result.data?.id,
      });
    }
  }, [result.data, updateAccount]);

  return [account, updateAccount, clearAccount] as const;
}
