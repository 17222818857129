import { createContext, createRootDuck } from "react-ducks";
import * as app from "./app";
import { AppState } from "../../utils/constants";

export const root = createRootDuck(app.default);

const NAME = "AppContext";
export const context = createContext<AppState, app.Action["type"]>(
  root.reducer,
  root.initialState,
  undefined,
  NAME,
  true
);
