import _ from "lodash";
import React from "react";
import { AppError, Application, ProductDetails } from "../../utils/constants";
import * as mocks from "../../utils/mocks";
import { useApiQuery } from "./useApiQuery";
import { useAppState, useProductIdSetter } from "./useAppDuck";
import { useViewedProducts } from "./useViewedProducts";

export function useProductDetails() {
  const [setProductId, existingProduct] = useProductDetailsById();

  const clearProductDetails = React.useCallback(() => {
    setProductId(undefined);
  }, [setProductId]);

  const setProductDetails = React.useCallback(
    (
      updateProduct:
        | Partial<ProductDetails>
        | ((arg0: ProductDetails) => Partial<ProductDetails>)
    ) => {
      // TODO: api call to actually mutate fetched product details
      const productRef = existingProduct ?? ({} as ProductDetails);
      const productDiff = _.isFunction(updateProduct)
        ? updateProduct(productRef)
        : updateProduct;
      Object.assign(productRef, productDiff);
      productRef.id && setProductId(productRef.id);
    },
    [existingProduct, setProductId]
  );

  return [existingProduct, setProductDetails, clearProductDetails] as const;
}

function useProductDetailsById() {
  const { api, product } = useAppState();
  const productId = product?.details?.id;
  const setProductId = useProductIdSetter();
  const [, setViewedProducts] = useViewedProducts();
  const productDetails = useProductDetailApi(productId, api?.key ?? undefined);

  React.useEffect(() => {
    if (!productDetails) return;
    // TODO: look into if any access to this code should be counted as a product view
    setViewedProducts((productList) => {
      const productIds = new Set(productList.map((p) => p.id));
      if (productIds.has(productDetails.id)) {
        return productList;
      }
      const productViewedKey = "viewed";
      const newProductList = _.sortBy(
        [
          { ...productDetails, [productViewedKey]: new Date() },
          ...(productList ?? []),
        ],
        productViewedKey
      );
      return newProductList;
    });
    setProductId(productDetails.id);
  }, [productDetails, setProductId, setViewedProducts]);

  return [setProductId, productDetails] as const;
}

function useProductDetailApi(
  productId?: string | null,
  apiKey?: string
): ProductDetails | undefined {
  const result = useApiQuery({
    mock: mocks.Product,
    key: apiKey,
    path: `${Application.Api.PRODUCT}/${productId}`,
    validate: () => {
      if (!productId) {
        throw new Error(AppError.INVALID_PRODUCT_INFO);
      }
    },
  });

  return result.data ?? undefined;
}
