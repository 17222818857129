import React from "react";
import {
  AppError,
  AppText,
  Color,
  ColorCSS,
  DimensionPx,
  FontFamily,
  FontSizeEm,
  Timing,
} from "../utils/constants";
import { asCssTransitions, setConditionalTimeout } from "../utils/react";

export interface FormFieldProps extends React.HTMLProps<HTMLInputElement> {
  formatInput?: (v: string) => string;
  label: string;
  onReturn: React.KeyboardEventHandler<HTMLInputElement>;
  validate?: (el: HTMLInputElement) => boolean;
  valueRef: React.MutableRefObject<{
    value?: string;
    isValid?: boolean;
    setValue?: (value: string) => void;
  }>;
}

export function FormField({
  label,
  formatInput = undefined,
  onChange,
  onReturn,
  onKeyUpCapture,
  valueRef,
  validate = validateInput,
  ...inputProps
}: FormFieldProps) {
  const isValid = valueRef.current.isValid;
  const [isEditing, setIsEditing] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const formattedValueRef = React.useRef("");

  const onValueChange = React.useCallback(
    function (e: React.ChangeEvent<HTMLInputElement>) {
      const inputTarget = e?.target ?? inputRef.current;
      if (!inputTarget) return;
      valueRef.current.value = inputTarget.value;
      formattedValueRef.current =
        (valueRef.current.value && formatInput?.(valueRef.current.value)) ??
        valueRef.current.value;
      // TODO: limit possible input to only valid values
      valueRef.current.isValid = validate(inputTarget);
      onChange?.(e);
    },
    [formatInput, onChange, validate, valueRef]
  );

  // change value from outside the component
  valueRef.current.setValue = React.useCallback(
    (v: string) => {
      setConditionalTimeout(
        () => !!inputRef.current,
        true,
        () => {
          if (!inputRef.current) {
            throw Error(AppError.FORM_FIELD_UNCONNECTED_INPUT);
          }
          inputRef.current.value = v;
          onValueChange({
            ...new Event("change"),
            target: inputRef.current,
          } as unknown as React.ChangeEvent<HTMLInputElement>);
        },
        Timing.Millis.ONE_SEC
      );
    },
    [onValueChange]
  );

  const handleKeyUpCapture = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") onReturn(e);
      return onKeyUpCapture?.(e);
    },
    [onKeyUpCapture, onReturn]
  );

  const onFocusChange = React.useCallback(
    (focusIn: boolean) => {
      setIsEditing((editing) => {
        const targetEl = inputRef.current;
        if (!targetEl) return editing;

        if (focusIn) targetEl.focus();

        if (editing !== focusIn) {
          const displayValue = focusIn
            ? valueRef.current.value ?? ""
            : formattedValueRef.current;
          targetEl.value = displayValue;

          try {
            targetEl.setSelectionRange(
              displayValue.length,
              displayValue.length
            );
          } catch (e) {
            // fails in cases like email input type
          }
        }
        return focusIn;
      });
      return false;
    },
    [valueRef]
  );

  const onFocusIn = React.useCallback(
    () => onFocusChange(true),
    [onFocusChange]
  );
  const onFocusOut = React.useCallback(
    () => onFocusChange(false),
    [onFocusChange]
  );

  const hasValue = !!valueRef.current.value;
  const showError = !isEditing && !isValid && hasValue;
  const errorHeight = DimensionPx.Layout.SPACING_3x;

  return (
    <div
      onClick={() => void setIsEditing(true)}
      style={{
        display: "flex",
        flexDirection: "column",
        marginBottom: DimensionPx.Layout.SPACING_2x,
        position: "relative",
        transition: asCssTransitions("border", "border-color"),
        width: `calc(100% + ${DimensionPx.Input.PADDING}px)`,
      }}
    >
      <input
        ref={inputRef}
        style={{
          borderColor:
            ColorCSS[
              showError
                ? Color.ALERT
                : isEditing
                ? Color.HIGHLIGHT
                : !hasValue
                ? Color.TRANSPARENT
                : Color.APPROVE
            ],
          borderRadius: DimensionPx.Input.HEIGHT,
          borderStyle: "solid",
          borderWidth: DimensionPx.Layout.SPACING_0x,
          color: ColorCSS[Color.BACKGROUND],
          flexGrow: 1,
          fontFamily: FontFamily.DEFAULT,
          fontSize: FontSizeEm.LARGE,
          height: DimensionPx.Input.HEIGHT,
          outline: "none",
          padding: `${DimensionPx.Layout.SPACING_1x}px ${DimensionPx.Input.PADDING}px`,
        }}
        placeholder={label}
        onBlur={onFocusOut}
        onChange={onValueChange}
        onClick={onFocusIn}
        onFocus={onFocusIn}
        onKeyUpCapture={handleKeyUpCapture}
        onMouseUp={onFocusIn}
        {...inputProps}
      />
      <div
        style={{
          alignItems: "end",
          bottom: -errorHeight,
          color: ColorCSS[Color.ALERT],
          display: "flex",
          height: showError ? errorHeight : 0,
          justifyContent: "center",
          opacity: Number(showError),
          overflow: "hidden",
          position: "absolute",
          transition: asCssTransitions("height", "opacity"),
          width: "100%",
        }}
      >
        {AppText.ENTER_VALID} {label.toLowerCase()}
      </div>
    </div>
  );
}

function validateInput(el: HTMLInputElement) {
  const pattern = el.getAttribute("pattern");
  if (pattern) {
    return new RegExp(pattern).test(el.value);
  }
  return true;
}
