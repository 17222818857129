import React from "react";
import { DimensionPx } from "../utils/constants";
import { mergeStyles } from "../utils/react";

interface ButtonIconProps
  extends React.PropsWithChildren<React.HTMLProps<HTMLSpanElement>> {}

export function ButtonIcon(props: ButtonIconProps) {
  const { style, ...spanProps } = props;
  const wrapperStyle = mergeStyles(ButtonIcon.wrapperStyle, style);
  return <span style={wrapperStyle} {...spanProps} />;
}

ButtonIcon.wrapperStyle = {
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  marginLeft: DimensionPx.Layout.SPACING_2x,
  marginRight: DimensionPx.Layout.SPACING_2x,
} as React.CSSProperties;
