import { AppError } from "./constants";

/**
 * Basic fetch wrapper providing api bearer token as well as other defaults
 */
export async function apiFetch<T>({
  mock,
  token,
  url,
}: {
  mock?: T;
  token?: string;
  url: string;
}) {
  if (mock) {
    return mock;
  } else if (!token) {
    throw new Error(AppError.UNAUTHORISED_API);
  }

  const result = await fetch(url, {
    headers: { Authorization: `Bearer ${token}` },
  });
  const data = await result.json();
  return data as T;
}
