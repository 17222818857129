import React from "react";
import {
  Account,
  AppError,
  AppState,
  AppText,
  AuditStatus,
  Color,
  ColorCSS,
  DimensionPx,
  FontSizeEm,
  Page,
  ProductDetails,
  Timing,
} from "../utils/constants";
import { intersperse } from "../utils/object";
import { formatDate, formatPrice, getProductOwnerId } from "../utils/product";
import {
  asCssTransition,
  asCssTransitions,
  componentKey,
  mergeStyles,
} from "../utils/react";
import { PageButton, PageButtonProps, PageButtonTheme } from "./PageButton";
import { ProductList } from "./ProductList";
import { Title } from "./Title";
import { useAccount } from "./hooks/useAccount";
import { useAppPageSetter, useAppState } from "./hooks/useAppDuck";
import { useProductDetails } from "./hooks/useProductDetails";
import { useWindowSize } from "./hooks/useWindowSize";

interface ProductDetailsProps extends React.HTMLProps<HTMLDivElement> {}

/**
 * List of products that the user owns
 */
export function ProductInformation({
  style,
  ...divProps
}: ProductDetailsProps) {
  const wrapperStyle = mergeStyles(style, ProductInformation.wrapperStyle);
  const [account] = useAccount();
  const [productDetails] = useProductDetails();
  const { page } = useAppState(); // TODO: remove unneeded use hook to get page
  const isProductOwner =
    page === Page.PRODUCT_PACKAGES || // TODO: only use check is product owner fn
    checkIsProductOwner(productDetails, account);
  const hasBundles = checkIsBundledProduct(productDetails);

  return (
    <div style={wrapperStyle} {...divProps}>
      <ProductPhotos
        urls={Array.from(
          new Set([
            ...(productDetails?.photos?.urls ?? []),
            ...(productDetails?.children?.flatMap((c) => c.photos.urls) ?? []),
          ])
        )}
        displayIndex={productDetails?.photos?.featured}
      />
      <ProductTitle>{productDetails?.name}</ProductTitle>
      <ProductDescription>
        {productDetails?.description?.split("\n")}
      </ProductDescription>
      <ProductHistory product={productDetails} />
      <div
        style={{
          alignContent: "center",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          paddingBottom: DimensionPx.Title.HEIGHT,
          paddingTop: DimensionPx.Layout.SPACING_2x,
          width: "100vw",
        }}
      >
        <ProductList items={productDetails?.children} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: DimensionPx.Layout.SPACING_2x,
            margin: DimensionPx.Button.PADDING,
            padding: DimensionPx.Button.PADDING,
          }}
        >
          {/* TODO: add note that this cancels all pending purchase request */}
          {isProductOwner && !productDetails?.active && (
            <PageButton theme={PageButtonTheme.NOTICE}>
              {AppText.ADD_ITEMS}
            </PageButton>
          )}
          {isProductOwner && hasBundles && (
            <PageButton theme={PageButtonTheme.DEFAULT}>
              {AppText.SHOW_BUNDLES}
            </PageButton>
          )}
          <PurchaseControlButton isProductOwner={isProductOwner} />
        </div>
      </div>
    </div>
  );
}

ProductInformation.pages = Object.freeze(
  new Set([Page.PRODUCT_SEARCH, Page.PRODUCT_PACKAGES])
);
ProductInformation.shouldRenderPage = (
  { page }: AppState,
  product?: ProductDetails
) => {
  return !!page && ProductInformation.pages.has(page) && !!product?.id;
};
ProductInformation.wrapperStyle = {
  backgroundColor: ColorCSS[Color.FOREGROUND],
  justifyContent: "initial",
  marginTop: DimensionPx.Title.HEIGHT,
  overflowY: "scroll",
  position: "relative",
  textAlign: "center",
} as React.CSSProperties;

// sub components
function ProductTitle(props: React.PropsWithChildren<{}>) {
  return <Title>{props.children}</Title>;
}

function ProductDescription(props: React.PropsWithChildren<{}>) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: DimensionPx.Layout.SPACING_1x,
        padding: DimensionPx.Layout.SPACING_3x,
        paddingTop: 0,
      }}
    >
      {React.Children.map(props.children, (line) => (
        <div>{line}</div>
      ))}
    </div>
  );
}

function ProductHistory(props: { product?: ProductDetails }) {
  const { product } = props;
  const appState = useAppState();
  const isRendering = ProductInformation.shouldRenderPage(appState, product);
  const [isExpanded, setIsExpanded] = React.useState(false);

  React.useEffect(() => {
    setIsExpanded(false);
  }, [isRendering]);

  if (!product) {
    return <div>{AppError.NO_PRODUCT_HISTORY}</div>;
  }

  const sharedStyle = {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    gap: DimensionPx.Layout.SPACING_2x,
    justifyContent: "center",
  } as const;
  const hasMoreThanOneOwner = product.history.length > 1;

  return (
    <div style={sharedStyle}>
      <ProductHistoryItem isExpanded {...product.history[0]} />
      {hasMoreThanOneOwner ? <HistoryConnector isExpanded /> : null}
      <div
        onMouseUp={isExpanded ? undefined : () => setIsExpanded(true)}
        style={{
          ...sharedStyle,
          transition: asCssTransitions("gap", "padding"),
        }}
      >
        {isExpanded ? null : (
          <span
            style={{
              color: ColorCSS[Color.APPROVE],
              fontSize: FontSizeEm.SMALL,
              fontWeight: "bold",
            }}
          >
            {AppText.EXPAND_PRODUCT_HISTORY}
          </span>
        )}
        {intersperse(
          product.history
            .slice(1, -1)
            .map((item, i) => (
              <ProductHistoryItem
                key={componentKey(ProductHistory, i)}
                {...item}
                isExpanded={isExpanded}
              />
            )),
          (_, i) => (
            <HistoryConnector
              isExpanded={isExpanded}
              key={componentKey(HistoryConnector, i)}
            />
          )
        )}
      </div>
      {hasMoreThanOneOwner ? (
        <>
          <HistoryConnector isExpanded />
          <ProductHistoryItem isExpanded {...product.history.slice(-1)[0]} />
        </>
      ) : null}
    </div>
  );
}

function HistoryConnector({ isExpanded }: { isExpanded: boolean }) {
  return (
    <span
      style={{
        backgroundColor: ColorCSS[Color.APPROVE],
        get borderRadius() {
          return this.width;
        },
        height: isExpanded ? DimensionPx.Layout.SPACING_3x : 0,
        opacity: Number(isExpanded),
        position: isExpanded ? "relative" : "absolute",
        transition: asCssTransitions("height", "opacity"),
        transitionDelay: `${Timing.Secs.Transition.DURATION}s`,
        width: DimensionPx.Layout.SPACING_1x,
      }}
    >
      <span
        style={{
          backgroundColor: ColorCSS[Color.APPROVE],
          borderRadius: DimensionPx.Layout.SPACING_1x * 2,
          get height() {
            return this.borderRadius;
          },
          get left() {
            return -this.borderRadius / 4;
          },
          position: "absolute",
          top: 0,
          get width() {
            return this.borderRadius;
          },
        }}
      ></span>
    </span>
  );
}

function ProductHistoryItem(
  props: ProductDetails["history"][number] & { isExpanded: boolean }
) {
  const multiplier = props.isExpanded ? 1 : 0;
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        fontWeight: "bold",
        position: props.isExpanded ? "relative" : "absolute",
        height: `calc(${multiplier} * ${DimensionPx.ProductItem.HEIGHT}px)`,
        justifyContent: "space-between",
        opacity: multiplier,
        transition: asCssTransitions("opacity", "height"),
      }}
    >
      <span style={{ fontSize: FontSizeEm.SMALL }}>{props.owner}</span>
      <span style={{ fontSize: FontSizeEm.REGULAR }}>
        {formatPrice(props.currency)}
      </span>
      <span style={{ fontSize: FontSizeEm.XSMALL }}>
        {formatDate(props.date?.acquired)}
      </span>
    </div>
  );
}

function ProductPhotos(props: { displayIndex?: number; urls?: string[] }) {
  const [displayIndex, setDisplayIndex] = React.useState(
    props.displayIndex ?? 0
  );
  const windowSize = useWindowSize();
  const mainImageSize = windowSize.width * 0.8;
  const thumbnailSize = windowSize.width * 0.1;

  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: DimensionPx.Layout.SPACING_2x,
      }}
    >
      <img
        alt="Displaying product"
        height={mainImageSize}
        src={props.urls?.[displayIndex]}
        width={mainImageSize}
        style={{
          backgroundImage: `repeating-conic-gradient(${
            ColorCSS[Color.FOREGROUND]
          } 0% 25%, transparent 0% 50%)
            50% / ${DimensionPx.Layout.SPACING_3x}px ${
            DimensionPx.Layout.SPACING_3x
          }px`,
          borderRadius: DimensionPx.Layout.SPACING_3x,
          padding: DimensionPx.Layout.SPACING_2x,
        }}
      />
      <div
        style={{
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
          gap: DimensionPx.Layout.SPACING_2x,
        }}
      >
        {props.urls?.map((url, idx) => {
          return (
            <img
              alt={`Product ${idx + 1}`}
              height={thumbnailSize}
              key={componentKey(ProductPhotos, idx)}
              onClick={() => setDisplayIndex(idx)}
              src={url}
              width={thumbnailSize}
              style={{
                borderColor:
                  idx === displayIndex
                    ? ColorCSS[Color.APPROVE]
                    : "transparent",
                borderStyle: "solid",
                borderWidth: DimensionPx.Layout.SPACING_0x,
                borderRadius: DimensionPx.Layout.SPACING_1x,
                transition: asCssTransition`border`,
              }}
            />
          );
        })}
      </div>
    </div>
  );
}

function PurchaseControlButton({
  isProductOwner,
}: {
  isProductOwner: boolean;
}) {
  const [account] = useAccount();
  const setAppPage = useAppPageSetter();
  const [productDetails, setProductDetails] = useProductDetails();
  const isProductEnabled = !!productDetails?.active;
  const isProductApproved = checkIsProductApproved(productDetails);
  const hasPendingPurchaseRequest =
    checkIsProductPurchaseRequest(productDetails);
  const isPotentialPurchaser = checkIsPotentialPurchaser(
    productDetails,
    account
  );

  const enableProductSale = React.useCallback(() => {
    setProductDetails({ active: true });
  }, [setProductDetails]);

  const disableProductSale = React.useCallback(() => {
    setProductDetails({ active: false });
  }, [setProductDetails]);

  const cancelPurchaseRequest = React.useCallback(() => {
    setProductDetails({ purchaseRequest: undefined });
  }, [setProductDetails]);

  const makePurchaseRequest = React.useCallback(() => {
    const id = account?.id;
    const name = account?.name;
    if (!id || !name) {
      console.warn(AppError.INVALID_USER_INFO);
      return setAppPage(Page.UN_AUTH);
    }
    const date = new Date();
    setProductDetails({ purchaseRequest: { id, name, date } });
  }, [account?.id, account?.name, setAppPage, setProductDetails]);

  const approvePurchaseRequest = React.useCallback(() => {
    setProductDetails((existing) => {
      if (!existing.purchaseRequest?.id) {
        throw new Error(AppError.INVALID_USER_INFO);
      }
      if (!existing.history[0]) {
        throw new Error(AppError.NO_PRODUCT_OWNER);
      }
      const newUserHistory = { ...existing.history[0] };
      Object.assign(newUserHistory, {
        date: { ...newUserHistory.date, acquired: new Date() },
      });
      newUserHistory.owner = existing.purchaseRequest.id;
      return {
        history: [newUserHistory, ...existing.history],
        purchaseRequest: undefined,
      };
    });
  }, [setProductDetails]);

  const purchaseControlButtonProps: PageButtonProps = {
    children: AppText.SALE_DISABLED,
    theme: PageButtonTheme.MUTED,
  };
  if (isProductApproved) {
    if (isProductOwner) {
      if (isProductEnabled) {
        if (hasPendingPurchaseRequest) {
          Object.assign(purchaseControlButtonProps, {
            children: AppText.SALE_APPROVE,
            onClick: approvePurchaseRequest,
            theme: PageButtonTheme.APPROVE,
          });
        } else {
          Object.assign(purchaseControlButtonProps, {
            children: AppText.SALE_DISABLE,
            onClick: disableProductSale,
            theme: PageButtonTheme.REJECT,
          });
        }
      } else {
        Object.assign(purchaseControlButtonProps, {
          children: AppText.SALE_ENABLE,
          onClick: enableProductSale,
          theme: PageButtonTheme.APPROVE,
        });
      }
    } else {
      if (isProductEnabled) {
        if (hasPendingPurchaseRequest) {
          if (isPotentialPurchaser) {
            Object.assign(purchaseControlButtonProps, {
              children: AppText.PURCHASE_CANCEL,
              onClick: cancelPurchaseRequest,
              theme: PageButtonTheme.REJECT,
            });
          } else {
            // product has been requested to be purchased by someone else
          }
        } else {
          Object.assign(purchaseControlButtonProps, {
            children: AppText.PURCHASE_REQUEST,
            onClick: makePurchaseRequest,
            theme: PageButtonTheme.APPROVE,
          });
        }
      } else {
        // product is not enabled by owner for sale
      }
    }
  }
  return <PageButton {...purchaseControlButtonProps} />;
}

// helpers
function isAuthenticated(account?: Account) {
  return !!account?.isAuthenticated;
}

function checkIsProductOwner(product?: ProductDetails, account?: Account) {
  return isAuthenticated(account) && getProductOwnerId(product) === account?.id;
}

function checkIsProductApproved(product?: ProductDetails) {
  return product?.audits?.[0]?.status === AuditStatus.APPROVED;
}

function checkIsBundledProduct(product?: ProductDetails) {
  return (product?.parents?.length ?? 0) > 0;
}

function checkIsProductPurchaseRequest(product?: ProductDetails) {
  return !!product?.purchaseRequest?.id;
}

function checkIsPotentialPurchaser(
  product?: ProductDetails,
  account?: Account
) {
  return (
    isAuthenticated(account) && product?.purchaseRequest?.id === account?.id
  );
}
