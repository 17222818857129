import { QueryErrorResetBoundary } from "@tanstack/react-query";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { mergeStyles } from "../utils/react";
import { RetryButton } from "./RetryButton";
import { AppError, ColorCSS, DimensionPx } from "../utils/constants";

function ErrorResetBoundary({ children }: React.PropsWithChildren<unknown>) {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          onReset={() => reset()}
          fallbackRender={({ error, resetErrorBoundary }) => {
            const errorMessage = (error as Error)?.message;

            return (
              <FullPageCenterChildren>
                <RetryButton onClick={() => resetErrorBoundary()} />
                <span style={{ color: ColorCSS.ALERT, fontWeight: "bold" }}>
                  {errorMessage || AppError.PAGE_LOAD_FAIL}
                </span>
              </FullPageCenterChildren>
            );
          }}
        >
          {children}
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
}

function FullPageCenterChildren({
  style,
  ...divProps
}: React.HTMLProps<HTMLDivElement>) {
  return (
    <div
      style={mergeStyles(style, {
        alignItems: "center",
        display: "flex",
        flexDirection: "column-reverse",
        gap: DimensionPx.Layout.SPACING_2x,
        height: "100vh",
        justifyContent: "center",
        left: 0,
        margin: 0,
        padding: 0,
        position: "fixed",
        textAlign: "center",
        top: 0,
        width: "100vw",
      })}
      {...divProps}
    />
  );
}

export default ErrorResetBoundary;
