import _ from "lodash";
import React from "react";
import { FaRegUserCircle } from "react-icons/fa";
import { FiEdit, FiLogOut } from "react-icons/fi";
import {
  AppError,
  AppText,
  Color,
  ColorCSS,
  DimensionPx,
  FontSizeEm,
  Page,
} from "../utils/constants";
import { formatDate } from "../utils/product";
import { mergeStyles } from "../utils/react";
import { AppControls } from "./AppControls";
import { useAccount } from "./hooks/useAccount";
import { UnAuthOptions } from "./UnAuthOptions";

interface AccountProfileProps extends React.HTMLProps<HTMLDivElement> {}

export function AccountProfile({ style, ...divProps }: AccountProfileProps) {
  const [account, , clearAccount] = useAccount();
  const [expandedSection, setExpandedSection] = React.useState(
    AppText.PERSONAL_DETAILS
  );

  const wrapperStyle = mergeStyles(style, AccountProfile.wrapperStyle);

  return (
    <div style={wrapperStyle} {...divProps}>
      <div style={{ overflow: "scroll" }}>
        <div
          style={{
            alignItems: "center",
            backgroundColor: ColorCSS[Color.FOREGROUND],
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            padding: DimensionPx.Layout.SPACING_3x,
            width: UnAuthOptions.wrapperStyle.width,
          }}
        >
          <div
            style={{
              fontSize: FontSizeEm.XLARGE,
              fontWeight: "bold",
            }}
          >
            {AppText.SALUT} {_.capitalize(account?.name?.split(" ")[0])}
          </div>
          <ProfileImage imageSrc={account?.images?.profile} />
        </div>
        <div
          style={{
            alignItems: "start",
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            gap: DimensionPx.Layout.SPACING_3x,
            padding: DimensionPx.Layout.SPACING_3x,
            paddingBottom: AppControls.bottomOffsetPx,
          }}
        >
          <Section
            icon={<FaRegUserCircle size={DimensionPx.Button.ICON} />}
            title={AppText.PERSONAL_DETAILS}
            isExpanded={expandedSection === AppText.PERSONAL_DETAILS}
            onClick={() => setExpandedSection(AppText.PERSONAL_DETAILS)}
          >
            <SectionLine tag={AppText.PROFILE_EMAIL}>
              {account?.email}
            </SectionLine>
            <SectionLine tag={AppText.PROFILE_PHONE}>
              {account?.phone}
            </SectionLine>
            <SectionLine tag={AppText.PROFILE_DOB}>
              {formatDate(account?.date?.birth)}
            </SectionLine>
          </Section>
          {/* <Section
            icon={<FaRegCreditCard size={DimensionPx.Button.ICON} />}
            title={AppText.PAYMENT_METHOD}
            isExpanded={expandedSection === AppText.PAYMENT_METHOD}
            onClick={() => setExpandedSection(AppText.PAYMENT_METHOD)}
          ></Section>
          <Section
            icon={<FaRegBell size={DimensionPx.Button.ICON} />}
            title={AppText.NOTIFICATIONS}
            isExpanded={expandedSection === AppText.NOTIFICATIONS}
            onClick={() => setExpandedSection(AppText.NOTIFICATIONS)}
          ></Section>
          <Section
            icon={<GoGear size={DimensionPx.Button.ICON} />}
            title={AppText.SETTINGS}
            isExpanded={expandedSection === AppText.SETTINGS}
            onClick={() => setExpandedSection(AppText.SETTINGS)}
          ></Section> */}
          <Section
            icon={<FiLogOut size={DimensionPx.Button.ICON} />}
            title={AppText.LOGOUT}
            isExpanded={expandedSection === AppText.LOGOUT}
            onClick={clearAccount}
          ></Section>
        </div>
      </div>
    </div>
  );
}

function Section(
  props: React.PropsWithChildren<{
    icon: React.ReactNode;
    isExpanded?: boolean;
    onClick?: () => unknown;
    title: string;
  }>
) {
  return (
    <div
      onClick={props.onClick}
      style={{
        alignItems: "start",
        display: "flex",
        flexDirection: "row",
        gap: DimensionPx.Layout.SPACING_3x,
        justifyContent: "start",
        width: "100%",
      }}
    >
      <div
        style={{
          alignItems: "center",
          aspectRatio: "1/1",
          backgroundColor: ColorCSS[Color.MAIN],
          borderRadius: "100%",
          color: ColorCSS[Color.BACKGROUND],
          display: "flex",
          gap: DimensionPx.Layout.SPACING_2x,
          justifyContent: "center",
          width: DimensionPx.Title.HEIGHT,
        }}
      >
        {props.icon}
      </div>
      <div
        style={{
          alignItems: "start",
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          gap: DimensionPx.Layout.SPACING_1x,
          justifyContent: "center",
        }}
      >
        <div
          style={{
            alignItems: "center",
            fontSize: FontSizeEm.LARGE,
            fontWeight: "bold",
            height: DimensionPx.Title.HEIGHT,
            display: "flex",
          }}
        >
          {props.title}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: DimensionPx.Layout.SPACING_2x,
          }}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
}

function SectionLine(props: React.PropsWithChildren<{ tag: string }>) {
  return (
    <div
      style={{
        alignItems: "center",
        display: "flex",
        justifyContent: "start",
        gap: DimensionPx.Layout.SPACING_1x,
      }}
    >
      <span style={{ fontWeight: "bold" }}>{props.tag}</span>
      <span>{props.children}</span>
    </div>
  );
}

function ProfileImage(props: { imageSrc?: string }) {
  const [imageSrc, setImageSrc] = React.useState(props.imageSrc);
  const imageFieldRef = React.useRef<HTMLInputElement>(null);
  const imageWrapperRef = React.useRef<HTMLDivElement>(null);

  const pickImageFile = React.useCallback(function () {
    imageFieldRef.current?.click();
  }, []);

  const handleImageUpload = React.useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(function (event) {
    const file = event.target.files?.[0];
    // Check if a file was selected and if it is a valid image format
    if (IMAGE_FILE_TYPES.includes(file?.type as any)) {
      const reader = new FileReader();
      reader.onload = function (e) {
        setImageSrc(e.target?.result?.toString());
        // TODO: upload file to account profile api
      };
      // Read the file as a Data URL (base64)
      reader.readAsDataURL(file!);
    } else {
      const errorMsg = `${AppError.INVALID_IMAGE_SELECTED} [${file?.type}]: Supported types are ${IMAGE_TYPES}`;
      throw new Error(errorMsg);
    }
  }, []);

  return (
    <div
      onClick={pickImageFile}
      ref={imageWrapperRef}
      style={{
        aspectRatio: "1/1",
        backgroundColor: ColorCSS[Color.MAIN],
        backgroundImage: `url('${imageSrc}')`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        borderColor: ColorCSS[Color.MAIN],
        borderRadius: "100%",
        borderStyle: "solid",
        borderWidth: DimensionPx.Layout.SPACING_0x,
        position: "relative",
        width: DimensionPx.ProductItem.HEIGHT,
      }}
    >
      {imageSrc ? null : (
        <FaRegUserCircle size={DimensionPx.ProductItem.HEIGHT} />
      )}
      <span
        style={{
          alignItems: "center",
          backgroundColor: ColorCSS[Color.FOREGROUND],
          borderRadius: "100%",
          bottom: 0,
          display: "flex",
          height: DimensionPx.Layout.SPACING_2x,
          justifyContent: "center",
          padding: DimensionPx.Layout.SPACING_1x,
          position: "absolute",
          right: 0,
          width: DimensionPx.Layout.SPACING_2x,
        }}
      >
        <FiEdit
          size={DimensionPx.Layout.SPACING_2x}
          color={ColorCSS[Color.APPROVE]}
        />
      </span>
      <input
        onChange={handleImageUpload}
        accept={ACCEPT_IMAGE_TYPES}
        ref={imageFieldRef}
        style={{ display: "none", visibility: "hidden" }}
        type="file"
      />
    </div>
  );
}

const IMAGE_TYPES = ["jpg", "jpeg", "png", "webp"] as const;
const IMAGE_FILE_TYPES = IMAGE_TYPES.map((t) => `image/${t}` as const);
const ACCEPT_IMAGE_TYPES = IMAGE_TYPES.map((t) => `.${t}` as const).join(
  "," as const
);

/** Pages that this component is displayed for */
AccountProfile.pages = Object.freeze(new Set([Page.ACCOUNT_PROFILE]));
AccountProfile.shouldRenderPage = undefined;
AccountProfile.wrapperStyle = {
  alignItems: "start",
  display: "flex",
  top: DimensionPx.Title.HEIGHT,
  width: `100vw`,
} as React.CSSProperties;
