import React from "react";
import { Page, ProductDetails } from "../utils/constants";
import { mergeStyles } from "../utils/react";
import { ProductList } from "./ProductList";
import { SearchControls } from "./SearchControls";
import { useSearchProducts } from "./hooks/useSearchProducts";
import { useViewedProducts } from "./hooks/useViewedProducts";
import _ from "lodash";

interface SearchProductProps extends React.HTMLProps<HTMLDivElement> {}

/**
 * List of products user previously viewed or are now searching for
 */
export function SearchProduct({ style, ...divProps }: SearchProductProps) {
  type OnSubmitFn = React.ComponentProps<typeof SearchControls>["onSubmit"];
  type SearchParams = Parameters<OnSubmitFn>[0];
  const [searchParams, setSearchParams] = React.useState<SearchParams>({
    filter: {},
    sort: {
      order: [],
      direction: {},
    },
  });
  // show search results when user has entered some search terms
  // otherwise just show the products user has viewed previously
  const showSearchResults = Boolean(searchParams.filter.name);
  const [viewedProducts, setViewedProducts] = useViewedProducts();
  const [searchResults, handleSearchProducts] = useSearchProducts();
  const removeProduct = React.useCallback(
    (id: ProductDetails["id"]) => {
      setViewedProducts((products) => _.filter(products, (p) => p.id !== id));
    },
    [setViewedProducts]
  );

  const onSubmit = React.useCallback(
    function onSubmit(params: SearchParams) {
      setSearchParams(params);
      handleSearchProducts(params);
    },
    [handleSearchProducts]
  );

  const wrapperStyle = mergeStyles(SearchProduct.wrapperStyle, style);
  const productItems = showSearchResults ? searchResults : viewedProducts;
  const removeItem = showSearchResults ? undefined : removeProduct;

  return (
    <div style={wrapperStyle} {...divProps}>
      <ProductList
        style={{ paddingTop: "50vh" }}
        filters={searchParams.filter}
        items={showSearchResults ? searchResults : viewedProducts}
        order={searchParams.sort}
        removeItem={removeItem}
      />
      <SearchControls hasItems={!!productItems.length} onSubmit={onSubmit} />
    </div>
  );
}

SearchProduct.pages = Object.freeze(new Set([Page.PRODUCT_SEARCH]));
SearchProduct.shouldRenderPage = undefined;
SearchProduct.wrapperStyle = {} as React.CSSProperties;
