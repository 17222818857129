import _ from "lodash";
import React from "react";
import { Application, ProductDetails } from "../../utils/constants";
import * as mocks from "../../utils/mocks";
import { useApiQuery } from "./useApiQuery";
import { useAppState } from "./useAppDuck";

export function useOwnedProducts() {
  const { api } = useAppState();
  const [ownedProducts, setOwnedProducts] = React.useState<
    readonly ProductDetails[]
  >([]);

  const result = useApiQuery({
    mock: mocks.OwnedProducts,
    path: Application.Api.PRODUCTS,
    key: api?.key ?? undefined,
  });

  React.useEffect(() => {
    if (!result.data) return;
    setOwnedProducts(result.data);
  }, [result.data]);

  const removeProduct = React.useCallback(function (id: ProductDetails["id"]) {
    setOwnedProducts((ownedProducts) =>
      _.filter(ownedProducts, (item) => item.id !== id)
    );
  }, []);

  return [ownedProducts, removeProduct] as const;
}
