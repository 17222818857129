import React from "react";
import { mergeStyles } from "../utils/react";
import { Ally, ColorCSS } from "../utils/constants";

interface PageButtonProps extends React.LinkHTMLAttributes<HTMLAnchorElement> {}

export function Link({ children, style, ...props }: PageButtonProps) {
  return (
    <a
      role={Ally.Role.LINK}
      style={mergeStyles(Link.wrapperStyle, style)}
      {...props}
    >
      {children}
    </a>
  );
}

Link.wrapperStyle = {
  alignItems: "center",
  color: ColorCSS.APPROVE,
  cursor: "pointer",
  fontWeight: "normal",
  justifyContent: "center",
  textAlign: "center",
  verticalAlign: "middle",
} as const;
