import React from "react";
import { MdClear } from "react-icons/md";
import {
  Color,
  ColorCSS,
  DimensionPx,
  FontSizeEm,
  LinkedProduct,
  ProductDetails,
} from "../utils/constants";
import { intersperse } from "../utils/object";
import { formatPrice } from "../utils/product";
import { componentKey, mergeStyles } from "../utils/react";
import { SearchControlParams } from "./SearchControls";
import { useProductDetails } from "./hooks/useProductDetails";

interface ProductListProps extends React.HTMLProps<HTMLDivElement> {
  filters?: SearchControlParams["filter"];
  order?: SearchControlParams["sort"];
  items?: readonly LinkedProduct[];
  removeItem?: (id: LinkedProduct["id"]) => void;
}

export function ProductList({
  items,
  style,
  removeItem,
  ...divProps
}: ProductListProps) {
  const [, setProductDetails] = useProductDetails();

  const productChildItems = React.useMemo(
    () =>
      items?.map((item, i) => {
        const itemThumbnail = item.photos?.urls[item.photos.featured ?? 0];
        return (
          <ProductContentItem
            account={item.account}
            currency={getItemCurrency(item)}
            key={componentKey(ProductContentItem, i)}
            name={item.name}
            thumbnailSrc={itemThumbnail}
            onRemove={removeItem && (() => removeItem(item.id))}
            onSelect={() => setProductDetails(item)}
          />
        );
      }) ?? [],
    [items, removeItem, setProductDetails]
  );

  const wrapperStyle = mergeStyles(ProductList.wrapperStyle, style);
  return (
    <div style={wrapperStyle} {...divProps}>
      {intersperse(productChildItems, (_, i) => (
        <ProductItemDivider key={componentKey(ProductItemDivider, i)} />
      ))}
    </div>
  );
}

function ProductItemDivider() {
  return (
    <div
      style={{
        backgroundColor: ColorCSS[Color.BACKGROUND],
        height: DimensionPx.Layout.SPACING_0x,
        opacity: 0.1,
        width: "100%",
      }}
    ></div>
  );
}

function ProductContentItem(props: {
  account: ProductDetails["account"];
  currency: ProductDetails["history"][number]["currency"];
  name: ProductDetails["name"];
  onRemove?: () => void;
  onSelect?: () => void;
  thumbnailSrc: string;
}) {
  const sharedStyle: React.CSSProperties = {
    alignItems: "center",
    clipPath: "content-box",
    display: "flex",
    flexDirection: "row",
    height: DimensionPx.ProductItem.HEIGHT,
    justifyContent: "space-between",
    overflow: "clip",
    padding: DimensionPx.Layout.SPACING_2x,
    position: "relative",
    whiteSpace: "nowrap",
  };
  return (
    <div
      style={{
        ...sharedStyle,
        justifyContent: "start",
      }}
    >
      <div
        onClick={props.onSelect}
        style={{
          ...sharedStyle,
          backgroundImage: `url(${props.thumbnailSrc})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          aspectRatio: "1/1",
        }}
      ></div>
      <div
        onClick={props.onSelect}
        style={{
          ...sharedStyle,
          alignItems: "start",
          flexDirection: "column",
          flexGrow: 1,
          fontWeight: "bold",
        }}
      >
        <span style={{ fontSize: FontSizeEm.REGULAR }}>{props.name}</span>
        <span style={{ fontSize: FontSizeEm.LARGE }}>
          {formatPrice(props.currency)}
        </span>
        <span style={{ fontSize: FontSizeEm.SMALL }}>
          {props.account?.manufacturer}
        </span>
      </div>
      <div onClick={props.onRemove}>
        {props.onRemove && (
          <MdClear
            size={DimensionPx.Button.ICON}
            color={ColorCSS[Color.ALERT]}
          />
        )}
      </div>
    </div>
  );
}

function getItemCurrency(item: LinkedProduct) {
  return item.history?.[0].currency;
}

ProductList.wrapperStyle = {
  overflow: "scroll",
  width: "100%",
} as React.CSSProperties;
