import { MetricType } from "web-vitals";

const reportWebVitals = async (onPerfEntry?: (metric: MetricType) => void) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    const webVitals = await import("web-vitals");
    webVitals.onCLS(onPerfEntry);
    webVitals.onINP(onPerfEntry);
    webVitals.onFCP(onPerfEntry);
    webVitals.onLCP(onPerfEntry);
    webVitals.onTTFB(onPerfEntry);
  }
};

export default reportWebVitals;
