import { Currency, ProductDetails } from "./constants";
import { suffixWith } from "./string";

export function getProductOwnerId(product?: ProductDetails) {
  return product?.history?.[0]?.owner;
}

export function formatPrice(
  data?: ProductDetails["history"][number]["currency"]
) {
  const currencySymbol = data?.type
    ? { ...Currency }[data.type as string] ?? data.type
    : "";
  return data
    ? `${currencySymbol}${data.fare.toLocaleString(navigator.language)}`
    : null;
}

export function formatDate(date?: Date) {
  return date ? date.toDateString() : null;
}

export function formatPhone(n: string) {
  const NUMBER_PLACEHOLDER = "#";
  const getNumberPart = (n: string, start: number, length: number) => {
    const value = n.substring(start, start + length);
    const suffix = new Array(length).fill(NUMBER_PLACEHOLDER).join("");
    return suffixWith(value, suffix);
  };

  const maxPhoneNumberLength = 10;
  const countryCode = n.substring(0, n.length - maxPhoneNumberLength);

  const areaCode = getNumberPart(n, countryCode.length, 3);
  const telPrefix = getNumberPart(n, countryCode.length + 3, 3);
  const lineNumber = getNumberPart(n, countryCode.length + 6, 4);

  return [
    countryCode && `+${countryCode}`,
    `(${areaCode}) ${telPrefix}-${lineNumber}`,
  ]
    .filter(Boolean)
    .join(" ");
}
