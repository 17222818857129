import React from "react";
import { AppError } from "../../utils/constants";
import { MOCK_API_KEY, MOCK_VALID_OTP } from "../../utils/mocks";
import { useAccount } from "./useAccount";
import { useApiKeySetter } from "./useAppDuck";

export function useAuthFlow() {
  const setApiKey = useApiKeySetter();
  const [account, updateAccount] = useAccount();

  const setUserEmail = React.useCallback(
    function setUserEmail(email: string) {
      updateAccount({ email });
    },
    [updateAccount]
  );

  const requestOTP = React.useCallback(
    function requestOTP(useEmail: boolean) {
      if (useEmail && account?.email) {
        // TODO: request email otp
      } else if (!useEmail && account?.phone) {
        // TODO: request phone otp
      } else {
        throw new Error(AppError.INVALID_USER_INFO);
      }
    },
    [account?.email, account?.phone]
  );

  const validateOTP = React.useCallback(
    async function validateOTP(otp: string) {
      // TODO: make api request to validate OTP
      if (otp === MOCK_VALID_OTP) {
        setApiKey(MOCK_API_KEY);
      } else {
        throw new Error(AppError.INVALID_OTP);
      }
    },
    [setApiKey]
  );

  return {
    /** Set the email that the authentication flow is being used for */
    setUserEmail,
    otp: {
      /** Requests OTP for a specific validation method */
      request: requestOTP,
      /** validates otp and sets the api token */
      validate: validateOTP,
    },
  } as const;
}
