import React from "react";
import { MdCancel } from "react-icons/md";
import {
  Color,
  ColorCSS,
  DimensionPx,
  Modal,
  PageLayer,
} from "../utils/constants";
import { asCssTransition, cssColorMix, mergeStyles } from "../utils/react";
import { AppErrorToast } from "./AppErrorToast";
import { ButtonIcon } from "./ButtonIcon";
import { LifeCycleTransition } from "./LifeCycleTransition";
import { PageButton } from "./PageButton";
import { PolicyDoc } from "./PolicyDoc";
import { useAppModalSetter, useAppState } from "./hooks/useAppDuck";

interface PageModalProps extends React.HTMLProps<HTMLDivElement> {}

export function PageModal({ style, ...divProps }: PageModalProps) {
  const elementProps = {
    style: mergeStyles(PageModal.wrapperStyle, style),
    ...divProps,
  };

  const { modal } = useAppState();
  const setAppModal = useAppModalSetter();
  const noModalSet = modal == null;

  return (
    <LifeCycleTransition
      dismount={noModalSet}
      transition={wrapperTransitionProp}
      {...elementProps}
    >
      <PolicyDoc
        doc={Modal[Modal.PRIVACY_POLICY]}
        isVisible={modal === Modal.PRIVACY_POLICY}
      />
      <PolicyDoc
        doc={Modal[Modal.TERMS_CONDITIONS]}
        isVisible={modal === Modal.TERMS_CONDITIONS}
      />
      <AppErrorToast isVisible={modal === Modal.ERROR} />
      <PageButton
        onClick={() => setAppModal(null)}
        style={{
          backgroundColor: "white",
          borderRadius: "100%",
          bottom: DimensionPx.Layout.SPACING_1x,
          color: ColorCSS[Color.BACKGROUND],
          padding: `${DimensionPx.Button.PADDING}px 0`,
          position: "absolute",
          right: DimensionPx.Layout.SPACING_1x,
          zIndex: PageLayer.ONE,
        }}
      >
        <ButtonIcon>
          <MdCancel size={DimensionPx.Button.HEIGHT} />
        </ButtonIcon>
      </PageButton>
    </LifeCycleTransition>
  );
}

const wrapperTransitionProp = LifeCycleTransition.getTransitionProp({
  css: {
    default: {
      transition: asCssTransition`background-color`,
      backgroundColor: ColorCSS[Color.TRANSPARENT],
      pointerEvents: "none",
    },
    mount: {
      backgroundColor: cssColorMix(Color.BACKGROUND, Color.TRANSPARENT, 1, 0.5),
      pointerEvents: "auto",
    },
  },
});

PageModal.wrapperStyle = {
  alignItems: "center",
  backgroundColor: "white",
  display: "flex",
  flexDirection: "column",
  gap: DimensionPx.Layout.SPACING_1x,
  height: "100vh",
  justifyContent: "end",
  left: 0,
  maxHeight: "100vh",
  maxWidth: "100vw",
  position: "absolute",
  top: 0,
  width: "100vw",
  zIndex: PageLayer.TWO,
} as Partial<React.CSSProperties>;
