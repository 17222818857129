import { isFunction } from "lodash";
import React from "react";
import { AppError } from "../../utils/constants";

/**
 * Example usage:
 * const [value, toggleValue] = useStateCycle(valueA, [valueA, valueB])
 *
 * @param initialState
 * @param nextStates
 * @returns
 */
export function useStateCycle<S>(
  initialState: S | (() => S),
  nextStates: ReadonlyArray<S> | ((current: S) => S)
) {
  const [value, setValue] = React.useState(initialState);

  // reset current value when total possible values change
  React.useEffect(() => {
    if (!isFunction(nextStates)) {
      const currentIdx = nextStates.findIndex((v) => v === value);
      // current value does not exist in list of possible values
      if (currentIdx < 0) setValue(nextStates[0]);
    }
  }, [value, nextStates]);

  const nextValue = React.useCallback(() => {
    setValue((current) => {
      if (isFunction(nextStates)) {
        return nextStates(current);
      } else {
        const currentIdx = nextStates.findIndex((v) => v === current);
        return nextStates[currentIdx + (1 % nextStates.length)];
      }
    });
  }, [nextStates]);

  return [value, nextValue, setValue] as const;
}

export function useSwitch(
  on = true,
  off = false,
  initial: typeof on | typeof off = on
) {
  const [value, toggle, setValue] = useStateCycle(initial, [on, off]);

  const switchOn = React.useCallback(() => setValue(on), [on, setValue]);
  const switchOff = React.useCallback(() => setValue(off), [off, setValue]);

  return {
    value,
    toggle,
    switch: {
      off: switchOff,
      on: switchOn,
    },
  } as const;
}

/** Used when we never want the value provided on first render to change */
export function useSetOnceOnly<T>(value: T) {
  const previous = React.useRef(value);

  React.useEffect(() => {
    if (!previous.current || previous.current === value) return;
    throw new Error(AppError.UNEXPECTED_KEY_CHANGE);
  }, [value]);
}

export function useAsyncValue<T>(expectValue: Promise<T>) {
  const [value, setValue] = React.useState<T | null>(null);
  React.useEffect(() => {
    expectValue.then((v) => setValue(v));
  });
  return value;
}
