import { merge } from "lodash";
import React from "react";
import { Timing } from "../utils/constants";
import { asCssTransition, mergeStyles } from "../utils/react";
import { useLifeCycle } from "./hooks/useLifecycle";

interface LifeCycleTransitionProps extends React.HTMLProps<HTMLDivElement> {
  /** Condition to move element out of view */
  dismount?: boolean;
  transition?: {
    css: {
      /** css applied to wrapper element regardless of mount state */
      default?: React.CSSProperties;
      /** css applied to wrapper element when mounted  */
      mount?: React.CSSProperties;
      /** css applied to wrapper element when unmounted */
      unmount?: React.CSSProperties;
    };
  };
}

export function LifeCycleTransition(
  {
    dismount = false,
    style,
    transition,
    ...divProps
  }: LifeCycleTransitionProps = LifeCycleTransition.initialProps
) {
  const { css } = LifeCycleTransition.getTransitionProp(transition)!;

  const [wrapperStyle, setWrapperStyle] = React.useState(
    mergeStyles(LifeCycleTransition.wrapperStyle, css?.default, style)
  );

  useLifeCycle({
    dismount,
    onMount: () => setWrapperStyle((styl) => mergeStyles(styl, css?.mount)),
    onDismount: () =>
      setWrapperStyle((styl) => mergeStyles(styl, css?.unmount)),
  });

  return (
    <div
      style={wrapperStyle}
      {...divProps}
      tabIndex={dismount ? -1 : undefined}
    />
  );
}

LifeCycleTransition.initialProps = Object.freeze({
  transition: {
    css: {
      default: {
        transition: asCssTransition`transform`,
        transitionDelay: `${Timing.Secs.Transition.DURATION}s`,
        /** Example: start with the element rendered out of view */
        transform: "translate(0, 200vh)",
      },
      mount: {
        transform: "translate(0, 0vh)",
        visibility: "inherit",
      },
      get unmount() {
        const { transition: _, ...cssProps } = this.default ?? {};
        return {
          ...cssProps,
          transitionDelay: `${Timing.Secs.Transition.DELAY}s`,
          visibility: "hidden",
        };
      },
    },
  },
} as Required<LifeCycleTransitionProps>);

LifeCycleTransition.getTransitionProp = (
  props?: Partial<LifeCycleTransitionProps["transition"]>
) => {
  return merge(
    {},
    LifeCycleTransition.initialProps.transition,
    props
  ) as LifeCycleTransitionProps["transition"];
};

LifeCycleTransition.wrapperStyle = {} as React.CSSProperties;
