import React from "react";
import { TbRefresh } from "react-icons/tb";
import {
  AppError,
  AppText,
  Color,
  ColorCSS,
  DimensionPx,
  FontFamily,
  FontSizeEm,
  Page,
  Timing,
} from "../utils/constants";
import { MOCK_VALID_OTP } from "../utils/mocks";
import { asCssTransition, componentKey, mergeStyles } from "../utils/react";
import { prefixWith } from "../utils/string";
import { AuthForm } from "./AuthForm";
import { PageButton, PageButtonTheme } from "./PageButton";
import { Title } from "./Title";
import { useAccount } from "./hooks/useAccount";
import {
  useAppErrorSetter,
  useAppPageSetter,
  useAppState,
} from "./hooks/useAppDuck";
import { useAuthFlow } from "./hooks/useAuthFlow";

interface AuthCodeProps extends React.HTMLProps<HTMLDivElement> {}

export function AuthCode({ style, ...divProps }: AuthCodeProps) {
  const { page } = useAppState();
  const setAppPage = useAppPageSetter();
  const wrapperStyle = mergeStyles(style, AuthCode.wrapperStyle);
  const [account] = useAccount();
  const [useEmail, setUseEmail] = React.useState<boolean>();
  const canUseEitherEmailOrPhone = Boolean(account?.email && account?.phone);
  const [requestOtpDate, setRequestOtpDate] = React.useState<Date>();
  const [otpValue, setOtpValue] = React.useState("");
  const [isValidOTP, setIsValidOtp] = React.useState(false);
  const validOtpLength = MOCK_VALID_OTP.length;
  const [sendNewOtp, timeToNextOtp] = useCanSendNewOTP(requestOtpDate);
  const setAppError = useAppErrorSetter();

  const {
    otp: { request: requestOTP, validate: validateOTP },
  } = useAuthFlow();

  const onOtpValueChange = React.useCallback(
    (otpValue: string) => {
      const isValid = otpValue.length === validOtpLength;
      setIsValidOtp(isValid);
      setOtpValue(otpValue);
    },
    [validOtpLength]
  );

  const handleSubmitOtp = React.useCallback(async () => {
    if (!isValidOTP) return;
    try {
      await validateOTP(otpValue);
      setAppPage(Page.ACCOUNT_PROFILE);
    } catch (e) {
      setAppError(AppError.INVALID_OTP);
    }
  }, [isValidOTP, otpValue, setAppError, setAppPage, validateOTP]);

  const handleRequestNewOTP = React.useCallback(
    (shouldUseEmail: boolean) => {
      requestOTP(shouldUseEmail);
      setRequestOtpDate(new Date());
      setUseEmail(shouldUseEmail);
    },
    [requestOTP]
  );

  const handleRequestOTP = React.useCallback(
    (shouldUseEmail: boolean) => {
      if (!sendNewOtp) return;
      handleRequestNewOTP(shouldUseEmail);
    },
    [handleRequestNewOTP, sendNewOtp]
  );

  // request OTP the first time this is launched
  React.useEffect(() => {
    setUseEmail((prevUseEmail) => {
      const newUseEmail = !!account?.email;
      if (page === Page.AUTH_CODE && prevUseEmail === undefined) {
        handleRequestOTP(newUseEmail);
        return newUseEmail;
      }
      return prevUseEmail;
    });
  }, [account?.email, handleRequestOTP, page]);

  return (
    <div style={wrapperStyle} {...divProps}>
      <Title>{AppText.ACCOUNT_OTP_FORM}</Title>
      <div>
        {useEmail
          ? AppText.ENTER_CODE_SENT_EMAIL
          : AppText.ENTER_CODE_SENT_PHONE}
      </div>
      <OtpField
        onChange={onOtpValueChange}
        length={validOtpLength}
        onReturn={handleSubmitOtp}
      />
      <Spacer height={0} />
      <div
        onClick={() => handleRequestOTP(!!useEmail)}
        style={{
          alignItems: "center",
          color: ColorCSS[sendNewOtp ? Color.APPROVE : Color.BACKGROUND],
          display: "flex",
          fontWeight: "bold",
          gap: DimensionPx.Layout.SPACING_1x,
          justifyContent: "center",
        }}
      >
        <TbRefresh
          size={DimensionPx.Button.ICON}
          style={{
            transform: `rotate(${sendNewOtp ? 15 : 105}deg)`,
            transition: asCssTransition`transform`,
          }}
        />
        <span
          style={{
            overflow: "hidden",
            textAlign: "left",
            textOverflow: "clip",
            textWrap: "nowrap",
            transition: asCssTransition`width`,
            width: `${sendNewOtp ? 8 : 12}em`,
          }}
        >
          {sendNewOtp
            ? AppText.RESEND_OTP
            : `${AppText.RESEND_OTP_WAIT} ${msToSecs(timeToNextOtp)}`}
        </span>
      </div>
      <Spacer height={0} />
      <div
        onClick={() => handleRequestNewOTP(!useEmail)}
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          gap: DimensionPx.Layout.SPACING_2x,
          justifyContent: "center",
          opacity: canUseEitherEmailOrPhone ? 1 : 0,
          pointerEvents: canUseEitherEmailOrPhone ? "inherit" : "none",
          transition: asCssTransition`opacity`,
        }}
      >
        <span>{AppText.NO_CODE_SENT}</span>
        <span style={{ color: ColorCSS[Color.APPROVE], fontWeight: "bolder" }}>
          {useEmail ? AppText.SEND_OTP_PHONE : AppText.SEND_OTP_EMAIL}
        </span>
      </div>
      <Spacer height={0} />
      <PageButton
        onClick={() => handleSubmitOtp()}
        style={{
          ...PAGE_BUTTON_STYLES,
          pointerEvents: isValidOTP ? "inherit" : "none",
        }}
        theme={isValidOTP ? PageButtonTheme.APPROVE : PageButtonTheme.MUTED}
      >
        {AppText.CONFIRM}
      </PageButton>
      <PageButton
        // TODO: submit only valid values to next page
        onClick={() => setAppPage(Page.AUTH_FORM)}
        style={PAGE_BUTTON_STYLES}
      >
        {AppText.GO_BACK}
      </PageButton>
    </div>
  );
}

function OtpField({
  length,
  onChange,
  onReturn,
}: {
  length: number;
  onChange: (value: string) => void;
  onReturn: () => unknown;
}) {
  const { page } = useAppState();
  // AuthCode.shouldRenderPage
  const isRenderingForm = !!page && AuthCode.pages.has(page);
  const [fieldIndex, setFieldIndex] = React.useState(0);

  const fieldRefs = React.useMemo(() => {
    return new Array(length)
      .fill(null)
      .map(() => React.createRef<HTMLInputElement>());
  }, [length]);

  const getCurrentValue = React.useCallback(
    () => fieldRefs.map((ref) => ref.current?.value || "").join(""),
    [fieldRefs]
  );

  React.useEffect(() => {
    if (!isRenderingForm) {
      // reset field index
      setFieldIndex(0);
      // reset field values
      fieldRefs.forEach((ref) => {
        if (!ref.current) return;
        ref.current.value = "";
      });
      // reset form value
      onChange("");
      return;
    }

    const elementInFocus = document.activeElement;
    const elementToFocus = fieldRefs[fieldIndex]?.current;
    if (!elementToFocus || elementInFocus === elementToFocus) return;

    const currentValue = getCurrentValue();

    setTimeout(
      () => elementToFocus.focus(),
      // TODO: find a better solution for this, maybe using React.useTransition
      Timing.Secs.Transition.DURATION *
        Timing.Millis.ONE_SEC *
        (Number(!currentValue.length) + 0.1)
    );
  }, [fieldRefs, fieldIndex, getCurrentValue, onChange, isRenderingForm]);

  const onValueUpdate = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      setFieldIndex((fieldIndex) => {
        const currentFieldHasValue = !!fieldRefs[fieldIndex].current?.value;
        const newFieldIndex = Math.min(
          length - 1,
          Math.max(0, fieldIndex + (currentFieldHasValue ? 1 : -1))
        );
        if (e.key === "Enter") {
          if (fieldIndex === newFieldIndex) onReturn();
          return fieldIndex;
        } else {
          return newFieldIndex;
        }
      });
      onChange(getCurrentValue());
    },
    [fieldRefs, getCurrentValue, length, onChange, onReturn]
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: DimensionPx.Layout.SPACING_2x,
      }}
    >
      {fieldRefs.map((ref, i) => (
        <OtpCharacterField
          active={fieldIndex === i}
          disabled={getCurrentValue().length < i && !ref.current?.value}
          fieldRef={ref}
          key={componentKey(OtpCharacterField, i)}
          onFocus={() => setFieldIndex(i)}
          onKeyUpCapture={onValueUpdate}
        />
      ))}
    </div>
  );
}

function OtpCharacterField({
  active,
  fieldRef,
  onChange,
  style,
  ...props
}: React.HTMLProps<HTMLInputElement> & {
  active: boolean;
  fieldRef?: React.RefObject<HTMLInputElement>;
}) {
  const inputStyle = mergeStyles(style, {
    borderColor:
      ColorCSS[
        props.disabled ? Color.MAIN : active ? Color.HIGHLIGHT : Color.APPROVE
      ],
    borderRadius: DimensionPx.Layout.SPACING_2x,
    borderStyle: "solid",
    borderWidth: DimensionPx.Layout.SPACING_0x,
    caretColor: "transparent",
    cursor: "pointer",
    fontFamily: FontFamily.DEFAULT,
    fontSize: FontSizeEm.LARGE,
    height: DimensionPx.Input.HEIGHT,
    outline: "none",
    pointerEvents: props.disabled ? "none" : "inherit",
    textAlign: "center",
    userSelect: "none",
    width: DimensionPx.Input.HEIGHT * 0.7,
  });

  const onValueChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const singleDigit = Number.parseInt(e.target.value.slice(-1));
      e.target.value = Number.isInteger(singleDigit) ? `${singleDigit}` : "";
      onChange?.(e);
    },
    [onChange]
  );

  const moveCaretToEnd = React.useCallback(() => {
    const field = fieldRef?.current;
    if (!field) return;
    field.setSelectionRange(field.value.length, field.value.length);
  }, [fieldRef]);

  return (
    <input
      onChange={onValueChange}
      onClick={moveCaretToEnd}
      onFocus={moveCaretToEnd}
      pattern="\d{1}"
      ref={fieldRef}
      style={inputStyle}
      type="tel"
      {...props}
    ></input>
  );
}

function Spacer({
  width = "100%",
  height = "100%",
}: Pick<React.CSSProperties, "height" | "width">) {
  return <div style={{ height, width }}></div>;
}

function useCanSendNewOTP(previousOtpDate?: Date) {
  const [result, setResult] = React.useState(canSendNewOTP());
  const sendNewOtp = result[1];
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setResult(canSendNewOTP(previousOtpDate));
    }, Timing.Millis.ONE_SEC);
    return () => clearTimeout(timeout);
  }, [previousOtpDate, sendNewOtp]);
  return result;
}

function canSendNewOTP(previousOtpDate?: Date, now: Date = new Date()) {
  let timeToSendNewOtp = 0;
  if (previousOtpDate) {
    const diff = now.getTime() - previousOtpDate.getTime();
    timeToSendNewOtp = Math.max(0, OTP_REQUEST_INTERVAL_MS - diff);
  }
  return [!timeToSendNewOtp, timeToSendNewOtp] as const;
}

function msToSecs(timeMs: number) {
  const timeSecs = Math.round(timeMs / Timing.Millis.ONE_SEC);
  const minutes = Math.floor(timeSecs / Timing.Secs.ONE_MIN);
  const seconds = timeSecs % Timing.Secs.ONE_MIN;
  return [prefixWith(minutes, "00"), prefixWith(seconds, "00")].join(":") + "s";
}

const PAGE_BUTTON_STYLES: React.CSSProperties = { width: "100%" };
const OTP_REQUEST_INTERVAL_MS =
  Timing.Millis.ONE_SEC * Timing.Secs.ONE_MIN * 3.01;

AuthCode.pages = new Set([Page.AUTH_CODE]);
AuthCode.shouldRenderPage = undefined;
AuthCode.wrapperStyle = {
  display: "flex",
  width: AuthForm.wrapperStyle.width,
} as React.CSSProperties;
