import React from "react";
import { AppText, DimensionPx } from "../utils/constants";
import { mergeStyles } from "../utils/react";
import { PageButton } from "./PageButton";

export function RetryButton({
  style,
  ...buttonProps
}: React.ComponentProps<typeof PageButton>) {
  const buttonStyle = mergeStyles(style, {
    margin: DimensionPx.Layout.SPACING_1x,
  });
  return (
    <PageButton style={buttonStyle} {...buttonProps}>
      {AppText.TRY_AGAIN}
    </PageButton>
  );
}
