import React from "react";
import { mergeStyles } from "../utils/react";
import { Ally, ColorCSS, DimensionPx } from "../utils/constants";

export enum PageButtonTheme {
  APPROVE,
  DEFAULT,
  GOOGLE,
  MUTED,
  NOTICE,
  REJECT,
}

export function PageButton({
  children,
  style,
  theme = PageButtonTheme.DEFAULT,
  ...props
}: PageButtonProps) {
  return (
    <a
      role={Ally.Role.BUTTON}
      style={mergeStyles(
        PageButton.wrapperStyle,
        style,
        PageButton.theme[PageButtonTheme.DEFAULT],
        PageButton.theme[theme]
      )}
      {...props}
    >
      {children}
    </a>
  );
}

PageButton.defaultHeightPx = DimensionPx.Button.HEIGHT;
PageButton.theme = {
  [PageButtonTheme.APPROVE]: {
    backgroundColor: ColorCSS.APPROVE,
    color: ColorCSS.FOREGROUND,
  },
  [PageButtonTheme.DEFAULT]: {
    backgroundColor: ColorCSS.BACKGROUND,
    color: ColorCSS.FOREGROUND,
  },
  [PageButtonTheme.GOOGLE]: {
    backgroundColor: "white",
    borderStyle: "solid",
    borderWidth: DimensionPx.Layout.SPACING_0x,
    color: ColorCSS.ALERT,
  },
  [PageButtonTheme.NOTICE]: {
    backgroundColor: ColorCSS.HIGHLIGHT,
    color: ColorCSS.FOREGROUND,
  },
  [PageButtonTheme.REJECT]: {
    backgroundColor: ColorCSS.ALERT,
    color: ColorCSS.FOREGROUND,
  },
  [PageButtonTheme.MUTED]: {
    backgroundColor: ColorCSS.FOREGROUND,
    color: ColorCSS.MAIN,
  },
} as const;
PageButton.wrapperStyle = {
  alignItems: "center",
  borderRadius: PageButton.defaultHeightPx,
  cursor: "pointer",
  display: "flex",
  flexDirection: "row",
  fontWeight: "bold",
  height: PageButton.defaultHeightPx,
  justifyContent: "center",
  padding: `0 ${DimensionPx.Button.PADDING}px`,
  textAlign: "center",
  textTransform: "uppercase",
  verticalAlign: "middle",
} as const;

export interface PageButtonProps
  extends React.LinkHTMLAttributes<HTMLAnchorElement> {
  theme?: keyof typeof PageButton.theme;
}
