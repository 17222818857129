import React from "react";
import { useErrorBoundary } from "react-error-boundary";

export function useMediaStream(constraints?: MediaStreamConstraints) {
  const [, setCycle] = React.useState(0);
  const streamRef = React.useRef<MediaStream | null>(null);
  const { showBoundary } = useErrorBoundary();

  const constraintsDep = JSON.stringify(constraints);
  React.useEffect(() => {
    if (streamRef.current?.active) return;
    const constr = JSON.parse(constraintsDep);
    navigator.mediaDevices
      ?.getUserMedia(constr)
      .then((stream) => {
        streamRef.current = stream;
        setCycle((c) => c + 1);
      })
      .catch((err) => {
        showBoundary(err);
      });

    return () => {
      streamRef.current?.getTracks().forEach((track) => {
        track.stop();
      });
    };
  }, [constraintsDep, showBoundary]);

  return streamRef.current;
}

export function useMediaDevices(constraints?: MediaStreamConstraints) {
  const stream = useMediaStream(constraints);

  return React.useMemo(() => {
    const mediaDevices =
      stream?.getTracks().flatMap((track) => {
        const capabilities = track.getCapabilities();
        if (!capabilities.deviceId) return [];

        const deviceId = capabilities.deviceId;
        const facingModes = Array.from(
          new Set([
            "environment", // default facing mode when none found
            ...(capabilities.facingMode || []),
          ] as VideoFacingModeEnum[])
        );
        return facingModes.map((facingMode) => ({
          deviceId,
          facingMode,
        }));
      }) ?? [];

    return mediaDevices as {
      deviceId: string;
      facingMode: VideoFacingModeEnum;
    }[];
  }, [stream]);
}
