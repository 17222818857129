import React from "react";
import { AppState, ProductDetails } from "../utils/constants";
import { mergeStyles } from "../utils/react";
import { LifeCycleTransition } from "./LifeCycleTransition";
import { useAppState } from "./hooks/useAppDuck";
import { useProductDetails } from "./hooks/useProductDetails";

export function PageFlowSection({
  name,
  shouldRenderPage,
  children,
  style,
  ...divProps
}: React.HTMLProps<HTMLDivElement> & {
  shouldRenderPage: (state: AppState, product?: ProductDetails) => boolean;
}) {
  const appState = useAppState();
  const sectionName = name ?? PageFlowSection.name;
  const dataTestId = `${sectionName}-pageSection`;
  const [productDetails] = useProductDetails();
  const renderPage = shouldRenderPage(appState, productDetails);

  const wrapperStyle = mergeStyles(PageFlowSection.wrapperStyle, style);

  return (
    <LifeCycleTransition
      data-testid={dataTestId}
      {...divProps}
      dismount={!renderPage}
      style={wrapperStyle}
    >
      {children}
    </LifeCycleTransition>
  );
}

PageFlowSection.wrapperStyle = {} as React.CSSProperties;
