import React from "react";
import { Page } from "../../utils/constants";
import { useAppPageSetter, useAppState } from "./useAppDuck";
import { useProductDetails } from "./useProductDetails";
import { ProductInformation } from "../ProductInformation";

export function usePageSetter() {
  const { page } = useAppState();
  const appPageSetter = useAppPageSetter();
  const [, setProductDetails, clearProductDetails] = useProductDetails();

  return React.useCallback(
    function pageSetter(params: { productId?: string; page: Page }) {
      appPageSetter(params.page);
      if (params.productId) {
        setProductDetails({ id: params.productId });
      } else if (
        !page ||
        page === params.page ||
        !ProductInformation.pages.has(params.page)
      ) {
        clearProductDetails();
      }
    },
    [appPageSetter, clearProductDetails, page, setProductDetails]
  );
}
