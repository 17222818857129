import React from "react";
import { IconBaseProps } from "react-icons";
import { Color, ColorCSS, DimensionPx } from "../utils/constants";
import { asCssTransition, mergeStyles } from "../utils/react";
import { ButtonIcon } from "./ButtonIcon";

interface ControlButtonProps extends React.ComponentProps<typeof ButtonIcon> {
  IconComponent: React.ComponentType;
  isActive?: boolean;
}

export function ControlButton({
  IconComponent,
  children,
  isActive = false,
  style,
  ...buttonIconProps
}: ControlButtonProps) {
  const buttonIconStyle = mergeStyles(style, ControlButton.wrapperStyle, {
    color: isActive ? ColorCSS[Color.APPROVE] : "inherit",
    transform: `scale(${isActive ? 1.2 : 1.0})`,
  });

  return (
    <ButtonIcon style={buttonIconStyle} {...buttonIconProps}>
      <IconComponent {...ControlButton.iconProps} />
      {children}
    </ButtonIcon>
  );
}

ControlButton.wrapperStyle = {
  flexGrow: 1,
  height: DimensionPx.Button.HEIGHT,
  transition: asCssTransition`transform`,
} as React.CSSProperties;

ControlButton.iconProps = {
  size: DimensionPx.Button.HEIGHT / 2,
} as IconBaseProps;
