import React from "react";
import {
  AppError,
  Color,
  ColorCSS,
  FacingModes,
  Timing,
} from "../utils/constants";
import { mergeStyles } from "../utils/react";
import { useInterval } from "./hooks/useInterval";
import { useMediaStream } from "./hooks/useMediaStream";

export function CameraStream({
  deviceId,
  facingMode,
  controlsRef,
  onErrorMessage,
  onScanProduct,
  overlay,
  ...videoProps
}: Omit<React.HTMLProps<HTMLVideoElement>, "height" | "width"> & {
  controlsRef: React.MutableRefObject<
    { captureImage: () => ImageData | void } | undefined
  >;
  deviceId: string;
  facingMode: VideoFacingModeEnum;
  height: number;
  onErrorMessage: (msg: string) => undefined;
  onScanProduct: (img?: ImageData) => unknown;
  overlay: React.ReactNode;
  width: number;
}) {
  const videoRef = React.useRef<HTMLVideoElement>(null); // Reference to the video element

  const captureImage = React.useCallback(
    function captureImage() {
      const canvas = document.createElement("canvas");

      // Set the canvas dimensions to match the video frame
      canvas.width = videoProps.width;
      canvas.height = videoProps.height;

      const canvasContext = canvas.getContext("2d");
      if (!canvasContext) {
        return onErrorMessage(AppError.CANVAS_CONTEXT_MISSING);
      }
      const currentVideoElem = videoRef.current;
      if (!currentVideoElem) {
        return onErrorMessage(AppError.VIDEO_ELEMENT_MISSING);
      }

      // Draw the current frame from the video to the canvas
      canvasContext.drawImage(
        currentVideoElem,
        0,
        0,
        canvas.width,
        canvas.height
      );

      // Get the image data from the canvas
      const imageData = canvasContext.getImageData(
        0,
        0,
        canvas.width,
        canvas.height
      );

      return imageData;
    },
    [onErrorMessage, videoProps.height, videoProps.width]
  );

  useInterval(() => {
    const imageData = captureImage();
    onScanProduct(imageData);
  }, Timing.Millis.ONE_SEC);

  controlsRef.current = { captureImage };

  const cameraStream = useMediaStream({ video: { deviceId, facingMode } });
  if (
    videoRef.current &&
    !(videoRef.current.srcObject as MediaStream)?.active
  ) {
    videoRef.current.srcObject = cameraStream;
  }

  const videoStyle = mergeStyles(VideoStyle, videoProps.style, {
    transform: facingMode === FacingModes.user ? "scaleX(-1)" : "initial",
  });

  return (
    <div style={WrapperStyle}>
      {overlay}
      <video
        autoPlay
        playsInline
        {...videoProps}
        ref={videoRef}
        style={videoStyle}
      />
    </div>
  );
}

const VideoStyle: React.CSSProperties = {
  objectFit: "cover",
  position: "absolute",
};

const WrapperStyle: React.CSSProperties = {
  alignItems: "center",
  backgroundColor: ColorCSS[Color.BACKGROUND],
  display: "flex",
  height: "100%",
  justifyContent: "center",
  position: "relative",
  width: "100%",
};
