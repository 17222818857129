/**
 * Generate random lowercase alphanumeric string
 * @param length of string
 */
export function alphanumeric(length: number) {
  let an = "";
  while (an.length < length) {
    an += Math.random().toString(36).slice(2);
  }
  return an.substring(0, length);
}
