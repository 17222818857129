import React from "react";
import { DimensionPx, FontSizeEm } from "../utils/constants";
import { mergeStyles } from "../utils/react";

interface TitleProps extends React.HTMLProps<HTMLSpanElement> {}

export function Title({ style, ...spanProps }: TitleProps) {
  return <span style={mergeStyles(Title.wrapperStyle, style)} {...spanProps} />;
}

Title.wrapperStyle = {
  alignItems: "center",
  fontSize: FontSizeEm.XLARGE,
  fontWeight: "bold",
  justifyContent: "center",
  textAlign: "center",
  margin: `${DimensionPx.Layout.SPACING_3x}px 0`,
} as React.CSSProperties;
