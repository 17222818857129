import {
  AuditStatus,
  Currency,
  ProductDetails,
  UserDetails,
} from "./constants";
import { alphanumeric } from "./random";

export const MOCK_API_KEY = "mock-api-key";
export const MOCK_VALID_OTP = "123456";

export enum UserIds {
  AUDITOR = "Mr. Auditor",
  BUYER = "Mrs. Buyer",
  MANUFACTURER = "Jamboree Goods",
  SELLER = "Berry Store",
}

// TODO: fetch this information from react url state
// https://github.com/iamogbz/react-state-url-fragment/blob/3c1c/demo/src/components/hooks/useAuthState.ts
const URL_SEARCH_PARAM_DEMO_USER = "demo-user";
export const User: UserDetails = {
  date: {
    birth: new Date("1990-01-19"),
  },
  email: "mock@email.com",
  get id() {
    return new URLSearchParams(window.location.search).get(
      URL_SEARCH_PARAM_DEMO_USER
    ) ?? Math.random() < 1
      ? UserIds.BUYER
      : UserIds.SELLER;
  },
  images: {
    // profile: "https://s3-alpha.figma.com/profile/81a6e3a2-eb14-4888-8948-e3953063fa2e",
  },
  name: "Inghard Mellow",
  setting: {
    notice: {
      purchase: {
        request: true,
        confirmation: true,
      },
    },
  },
} as const;

export const Product: ProductDetails = {
  id: "mock-product-id",
  description: `Homemade jams with all natural ingredients, crafted with love and care using the freshest, hand-picked fruits and 100% natural ingredients. Each jar is a celebration of pure flavors, free from preservatives, artificial sweeteners, or additives—just wholesome goodness straight from nature to your table.

Our jams are slow-cooked in small batches to preserve the vibrant taste of the fruits, ensuring every spoonful bursts with authentic, rich flavors. Whether you're spreading it on toast, pairing it with cheese, or swirling it into yogurt, our jams promise to elevate your meals with their delightful sweetness and smooth texture`,
  active: true,
  account: {
    manufacturer: UserIds.MANUFACTURER,
  },
  audits: [
    {
      date: new Date("2024-01-11"),
      notes: "No defects found",
      status: AuditStatus.APPROVED,
      verifier: UserIds.AUDITOR,
    },
  ],
  history: [
    {
      owner: UserIds.SELLER,
      date: { acquired: new Date("2024-05-11") },
      currency: {
        type: Currency.NGN,
        fare: 199999.99,
      },
    },
    {
      owner: "Corner Store Retail",
      date: { acquired: new Date("2024-04-11") },
      currency: {
        type: Currency.NGN,
        fare: 159999.99,
      },
    },
    {
      owner: "Jamboree Distributor - Lagos",
      date: { acquired: new Date("2024-03-11") },
      currency: {
        type: Currency.NGN,
        fare: 129999.99,
      },
    },
    {
      owner: UserIds.MANUFACTURER,
      date: { acquired: new Date("2024-01-02") },
      currency: {
        type: Currency.NGN,
        fare: 99999.99,
      },
    },
  ],
  name: "Jamboree Pack",
  children: [
    {
      id: "mock-child-id-1",
      name: "Homemade Apricot Jamboree",
      account: {
        manufacturer: UserIds.MANUFACTURER,
      },
      history: [
        {
          currency: {
            fare: 129999.99,
            type: Currency.NGN,
          },
        },
      ],
      photos: {
        featured: 1,
        urls: [
          "https://mir-s3-cdn-cf.behance.net/project_modules/max_3840/7072a443494769.57f1f74b9a766.jpg",
          "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/b2fdd943494769.57f1f74b9b0d0.jpg",
        ],
      },
    },
    {
      id: "mock-child-id-2",
      name: "Homemade Strawberry Jamboree",
      account: {
        manufacturer: UserIds.MANUFACTURER,
      },
      history: [
        {
          currency: {
            fare: 129999.99,
            type: Currency.NGN,
          },
        },
      ],
      photos: {
        featured: 0,
        urls: [
          "https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/a39ce843494769.57f1f74b9abf5.jpg",
          "https://mir-s3-cdn-cf.behance.net/project_modules/max_3840/90f83543494769.57f1f74b9a192.jpg",
        ],
      },
    },
  ],
  parents: [
    {
      id: "mock-parent-id",
      name: "Jamboree Mega Pack",
      account: {
        manufacturer: UserIds.MANUFACTURER,
      },
      history: [
        {
          currency: {
            fare: 1299999.99,
            type: Currency.NGN,
          },
        },
      ],
      photos: { urls: [] },
    },
  ],
  photos: {
    urls: [
      "https://mir-s3-cdn-cf.behance.net/project_modules/max_3840/90f83543494769.57f1f74b9a192.jpg",
      "https://mir-s3-cdn-cf.behance.net/project_modules/max_3840/7072a443494769.57f1f74b9a766.jpg",
    ],
  },
  purchaseRequest: undefined,
  tags: [
    {
      name: "SKU",
      slug: "mock-product-stock-keeping-unit-code",
    },
    {
      name: "UPC",
      slug: "mock-global-standard-universal-product-code",
    },
  ],
} as const;

export const OwnedProducts = Object.freeze([
  Product,
  ...new Array(9).fill(Product).map((p) => ({ ...p, id: alphanumeric(10) })),
]) as readonly ProductDetails[];
