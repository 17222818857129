import React from "react";
import { AppText, Page } from "../utils/constants";
import { formatPhone } from "../utils/product";
import { mergeStyles } from "../utils/react";
import { FormField, FormFieldProps } from "./FormField";
import { PageButton, PageButtonTheme } from "./PageButton";
import { Title } from "./Title";
import { UnAuthOptions } from "./UnAuthOptions";
import { useAccount } from "./hooks/useAccount";
import { useAccountSetter, useAppPageSetter } from "./hooks/useAppDuck";

interface AuthFormProps extends React.HTMLProps<HTMLDivElement> {}

export function AuthForm({ style, ...divProps }: AuthFormProps) {
  const setAppPage = useAppPageSetter();
  const wrapperStyle = mergeStyles(style, AuthForm.wrapperStyle);
  const pageButtonStyles: React.CSSProperties = { width: "100%" };
  const emailValueRef = React.useRef<FormFieldProps["valueRef"]["current"]>({});
  const phoneValueRef = React.useRef<FormFieldProps["valueRef"]["current"]>({});
  const setAccount = useAccountSetter();
  const [isReady, setIsReady] = React.useState(false);

  const [account] = useAccount();
  const { email, phone } = account ?? {};
  React.useEffect(() => {
    email && emailValueRef.current.setValue?.(email);
    phone && phoneValueRef.current.setValue?.(phone);
  }, [email, phone]);

  const onValueChange = React.useCallback(() => {
    setIsReady(() => {
      return Boolean(
        emailValueRef.current.isValid || phoneValueRef.current.isValid
      );
    });
  }, []);

  const handleSubmit = React.useCallback(() => {
    const email = emailValueRef.current.isValid
      ? emailValueRef.current.value
      : null;
    const phone = phoneValueRef.current.isValid
      ? phoneValueRef.current.value
      : null;
    // only set account email and phone to valid values
    setAccount({ account: { email, phone } });
    // if at least one valid field
    if (emailValueRef.current.isValid || phoneValueRef.current.isValid) {
      setAppPage(Page.AUTH_CODE);
    }
  }, [setAccount, setAppPage]);

  return (
    <div style={wrapperStyle} {...divProps}>
      <Title>{AppText.ACCOUNT_AUTH_FORM}</Title>
      <FormField
        label={AppText.PROFILE_EMAIL}
        onChange={onValueChange}
        onReturn={handleSubmit}
        pattern="[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,}$"
        required
        type="email"
        valueRef={emailValueRef}
      />
      <FormField
        formatInput={formatPhone}
        label={AppText.PROFILE_PHONE}
        onChange={onValueChange}
        onReturn={handleSubmit}
        pattern="\d{6,11}"
        required
        type="tel"
        valueRef={phoneValueRef}
      />
      <Spacer height={0} />
      <PageButton
        onClick={() => handleSubmit()}
        style={{
          ...pageButtonStyles,
          pointerEvents: isReady ? "inherit" : "none",
        }}
        theme={isReady ? PageButtonTheme.APPROVE : PageButtonTheme.MUTED}
      >
        {AppText.CONTINUE}
      </PageButton>
      <PageButton
        // TODO: submit only valid values to next page
        onClick={() => setAppPage(Page.UN_AUTH)}
        style={pageButtonStyles}
      >
        {AppText.GO_BACK}
      </PageButton>
    </div>
  );
}

function Spacer({
  width = "100%",
  height = "100%",
}: Pick<React.CSSProperties, "height" | "width">) {
  return <div style={{ height, width }}></div>;
}

AuthForm.pages = new Set([Page.AUTH_FORM]);
AuthForm.shouldRenderPage = undefined;
AuthForm.wrapperStyle = {
  display: "flex",
  width: UnAuthOptions.wrapperStyle.width,
} as React.CSSProperties;
