import { FiSearch, FiSliders } from "react-icons/fi";
import { DimensionPx } from "../utils/constants";
import { AppControls } from "./AppControls";
import { ButtonIcon } from "./ButtonIcon";
import { PageButton, PageButtonTheme } from "./PageButton";

export function SearchControls(props: {
  hasItems: boolean;
  onSubmit: (params: SearchControlParams) => unknown;
}) {
  return (
    <div style={SearchControls.wrapperStyle}>
      <PageButton theme={PageButtonTheme.APPROVE}>
        <ButtonIcon>
          <FiSearch size={DimensionPx.Button.ICON} />
        </ButtonIcon>
      </PageButton>
      <PageButton
        theme={props.hasItems ? PageButtonTheme.APPROVE : PageButtonTheme.MUTED}
      >
        <ButtonIcon>
          <FiSliders size={DimensionPx.Button.ICON} />
        </ButtonIcon>
      </PageButton>
    </div>
  );
}

SearchControls.wrapperStyle = {
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
  gap: DimensionPx.Layout.SPACING_2x,
  height: AppControls.bottomOffsetPx,
  paddingLeft: DimensionPx.Layout.SPACING_2x,
  paddingRight: DimensionPx.Layout.SPACING_2x,
  width: `calc(100% - ${DimensionPx.Layout.SPACING_2x * 2}px)`,
} as React.CSSProperties;

export interface SearchControlParams {
  filter: {
    name?: string;
  };
  sort: {
    /** The preference of sorting ordinals */
    order: SortField[];
    direction: Partial<Record<SortField, SortDirection>>;
  };
}

enum SortField {
  /** How much the item costs in its local currency */
  PRICE,
  /** When last the item was viewed by user */
  RECENCY,
  /** Score how closely the item matches filter parameters */
  RELEVANCE,
  /** How many units of the item have sold. TODO: take into account bundling */
  SALES_COUNT,
}

enum SortDirection {
  ASCENDING,
  DEFAULT = ASCENDING,
  DESCENDING,
}
